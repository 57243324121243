import { ArrowLeftOnRectangleIcon, PhoneIcon, ShoppingCartIcon, UserCircleIcon, UserIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import Logo from "../asset/images/logo.png";
import { Fragment, useCallback, useState } from "react";
import { Link, useSearchParams } from "react-router-dom"
import { useAuth } from "../context/authContext";
import { Menu } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import classNames from "../helper/ClassNames";
import { useNavigate } from "react-router-dom";
import { useProduct } from "../context/productContext";
import { useLocation } from "react-router-dom";
export default function Header({ children }) {
    const [showBar, setShowBar] = useState(false)
    const { user, logout } = useAuth()
    const { searchFilter, searchData, cartItems } = useProduct()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("q")
    const [hideResult, setHideResult] = useState(false)
    const [search, setSearch] = useSearchParams({ q: '' })
    const q = search.get("q")

    const handleSearch = useCallback(() => {
        if (q !== '' && query) {
            searchFilter(query)
        }
    }, [q, query, searchFilter])

    const push = useNavigate()

    const handleLogout = async () => {
        await logout()
        push("/login")
    }

    const nav = [
        {
            icon: UserIcon,
            title: "User Profile",
            href: '/profile'
        },
        {
            icon: ShoppingBagIcon,
            title: "Your orders",
            href: "/order-details"
        },
        {
            icon: PhoneIcon,
            title: "Help & Contact",
            href: '/profile'
        },
        {
            icon: ArrowLeftOnRectangleIcon,
            title: "Layout",
            onClick: () => handleLogout()
        },
    ]

    const handleQuery = (e) => {
        setSearch((prev) => {
            prev.set("q", e.target.value)
            return prev
        }, { replace: true })
        searchFilter(e.target.value)
        setHideResult(false)
    }

    return (
        <div>
            <div className="md:hidden flex z-10 items-center justify-between sticky top-0 bg-gray-800  justify-center w-full flex-wrap sm:flex-nowrap gap-2">
                <img className="w-fit h-[4rem] p-2 mx-3" src={Logo} alt=" brand-icon" />

                <div className="flex items-center gap-10 mr-4">
                    {/* <Menu as="div" className="relative">
                    <Menu.Button className="inline-flex w-full justify-center text-sm font-medium  focus:outline-none">
                        <BellAlertIcon className="w-9 h-9 text-white" />
                        <p className="absolute -top-2 p-1 text-xs  left-[1rem] bg-red-500 w-6 h-6 rounded-full text-white">
                            {user && user.notification.length}
                        </p>
                    </Menu.Button>
                    {user?.notification.length !== 0 &&
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute p-2 max-h-[45vh] overflow-y-scroll -right-21 md:right-0 mt-2 w-[15rem] md:w-[20rem] origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="px-1 py-1 ">
                                    <h2 className="text-md font-semibold py-2 border-b">Notifications</h2>
                                    {user?.notification.map((option, index) => (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        'group flex w-full  cursor-pointer items-center rounded-md px-2 py-3 text-sm border-b',
                                                        active
                                                            ? 'bg-gray-50 text-gray-900'
                                                            : 'text-gray-900',
                                                    )}
                                                >
                                                    <BellSnoozeIcon className="w-6 h-6 mr-2" />
                                                    <div>
                                                        <h2 className="text-sm text-gray-700 font-medium flex-1">{option.title}</h2>
                                                        <p className="text-xs text-gray-600">{option.description}</p>
                                                        <div className="flex justify-between w-full">
                                                            <a href={option.href} className="text-xs text-blue-500 text-end py-1">View offer</a>
                                                            <p onClick={() => handleRemoveNofi(index)} className="text-xs shadow-sm border py-1 border text-gray-700 px-2">Remove</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    }
                </Menu> */}
                    <Link to="/cart" className="flex relative w-full justify-center text-sm font-medium  focus:outline-none">
                        <ShoppingCartIcon className="w-10 h-10 text-white cursor-pointer" />
                        <p className="absolute -top-2 py-1 px-2 text-xs  left-[1.5rem] bg-red-500 w-6 h-6 rounded-full text-white">
                            {cartItems && cartItems.length}
                        </p>
                    </Link>
                    <Menu as="div" className="relative w-full">
                        <Menu.Button className="inline-flex w-full my-5 md:my-1 justify-center text-sm font-medium  focus:outline-none">
                            {user && user.photoUrl ?
                                <img onClick={() => setShowBar(!showBar)} src={user.photoUrl} className="w-7 object-cover h-7 cursor-pointer rounded-full shadow" alt="logo" />
                                :
                                <UserCircleIcon onClick={() => setShowBar(!showBar)} className="w-8 h-8 mr-5 text-white cursor-pointer" />
                            }
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute p-2 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="px-1 py-1 ">
                                    {nav.map((option, index) => (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <a href={option.href}
                                                    onClick={option.onClick}
                                                    className={classNames(
                                                        'group flex w-full  cursor-pointer items-center rounded-md px-2 py-3 text-sm',
                                                        active
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-900',
                                                    )}
                                                >
                                                    <option.icon className="w-5 h-5 mr-2" />
                                                    {option.title}
                                                </a>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
                <div className="flex items-center relative mb-6  md:mb-0  md:mx-0  w-full bg-gray-700 sm:w-[50%] mx-7   md:mt-0 md:mx-5 rounded-lg">
                    <div className={classNames('px-3', user.admin && "hidden")}>
                        <MagnifyingGlassIcon className="w-5 text-white h-5" />
                    </div><input
                        className="outline-none w-full text-sm bg-white py-[5px] pl-2 pr-10 rounded-r-lg "
                        id="q"
                        type="text"
                        onChange={(e) => handleQuery(e)}
                        value={q}
                        placeholder="Search Shopy Spy" />
                    {searchData.length && !hideResult && q !== '' ?
                        <div className="bg-white shadow-md absolute top-10 overflow-y-scroll z-50 h-auto max-h-[70vh] w-full rounded-md">
                            {searchData.map((item, key) => (
                                <Link onClick={() => setHideResult(true)} to={`/product/${item._id}`} key={key} className="flex gap-2 hover:bg-gray-100 border border-b justify-between px-5 py-3 ">
                                    <div className="flex  gap-4 items-center">
                                        <MagnifyingGlassIcon className="w-5  block h-5" />
                                        <p className="text-sm flex-1">{item.brandName}</p>
                                    </div>
                                    <img src={item.image} className="w-10 h-10 rounded-full" alt="searchLogo" />
                                </Link>
                            ))}
                        </div>
                        : q && !hideResult !== '' ?
                            <div className="bg-white py-4 px-3 text-center shadow-md absolute top-10 overflow-y-scroll z-50 h-auto max-h-[40vh] w-full rounded-md">
                                <div className="flex gap-4 items-center">
                                    <MagnifyingGlassIcon className="w-5 h-5" />
                                    <p className="text-sm font-bold text-gray-800">Result Not Found</p>
                                </div>
                            </div> : ""
                    }
                </div>


            </div >
            <div className="hidden md:block sticky top-0 z-20">
                <div className="flex  items-center justify-between  bg-gray-800  justify-center w-full flex-wrap sm:flex-nowrap gap-2">
                    <img className="w-fit h-[4rem] p-2 mx-3" src={Logo} alt=" brand-icon" />
                    <div className={classNames('flex items-center relative mb-6  md:mb-0  md:mx-0  w-full bg-gray-700 sm:w-[50%] mx-7   md:mt-0 md:mx-5 rounded-lg')}>
                        <div className="px-3">
                            <MagnifyingGlassIcon className="w-5 text-white h-5" />
                        </div>
                        <input
                            onClick={handleSearch}
                            className="outline-none w-full text-sm bg-white py-[8px] pl-2 pr-10 rounded-r-lg "
                            id="q"
                            type="text"
                            onChange={(e) => handleQuery(e)}
                            value={q}
                            placeholder="Search Shopy Spy"

                        />
                        {searchData.length && !hideResult && q !== '' ?
                            <div className="bg-white shadow-md absolute top-10 overflow-y-scroll z-50 h-auto max-h-[70vh] w-full rounded-md">
                                {searchData.map((item, key) => (
                                    <Link onClick={() => setHideResult(true)} to={`/product/${item._id}`} key={key} className="flex gap-2 hover:bg-gray-100 border border-b justify-between px-5 py-3 ">
                                        <div className="flex  gap-4 items-center">
                                            <MagnifyingGlassIcon className="w-5  block h-5" />
                                            <p className="text-sm flex-1">{item.brandName}</p>
                                        </div>
                                        <img src={item.image} className="w-10 h-10 rounded-full" alt="searchLogo" />
                                    </Link>
                                ))}
                            </div>
                            : q && !hideResult !== '' ?
                                <div className="bg-white py-4 px-3 text-center shadow-md absolute top-10 overflow-y-scroll z-50 h-auto max-h-[40vh] w-full rounded-md">
                                    <div className="flex gap-4 items-center">
                                        <MagnifyingGlassIcon className="w-5 h-5" />
                                        <p className="text-sm font-bold text-gray-800">Result Not Found</p>
                                    </div>
                                </div> : ""
                        }
                    </div>
                    <div className="flex items-center gap-10 mr-4">
                        {/* <Menu as="div" className="relative">
                    <Menu.Button className="inline-flex w-full justify-center text-sm font-medium  focus:outline-none">
                        <BellAlertIcon className="w-9 h-9 text-white" />
                        <p className="absolute -top-2 p-1 text-xs  left-[1rem] bg-red-500 w-6 h-6 rounded-full text-white">
                            {user && user.notification.length}
                        </p>
                    </Menu.Button>
                    {user?.notification.length !== 0 &&
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute p-2 max-h-[45vh] overflow-y-scroll -right-21 md:right-0 mt-2 w-[15rem] md:w-[20rem] origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="px-1 py-1 ">
                                    <h2 className="text-md font-semibold py-2 border-b">Notifications</h2>
                                    {user?.notification.map((option, index) => (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        'group flex w-full  cursor-pointer items-center rounded-md px-2 py-3 text-sm border-b',
                                                        active
                                                            ? 'bg-gray-50 text-gray-900'
                                                            : 'text-gray-900',
                                                    )}
                                                >
                                                    <BellSnoozeIcon className="w-6 h-6 mr-2" />
                                                    <div>
                                                        <h2 className="text-sm text-gray-700 font-medium flex-1">{option.title}</h2>
                                                        <p className="text-xs text-gray-600">{option.description}</p>
                                                        <div className="flex justify-between w-full">
                                                            <a href={option.href} className="text-xs text-blue-500 text-end py-1">View offer</a>
                                                            <p onClick={() => handleRemoveNofi(index)} className="text-xs shadow-sm border py-1 border text-gray-700 px-2">Remove</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    }
                </Menu> */}
                        <Link to="/cart" className="flex relative w-full justify-center text-sm font-medium  focus:outline-none">
                            <ShoppingCartIcon className="w-10 h-10 text-white cursor-pointer" />
                            <p className="absolute -top-2 py-1 px-2 text-xs  left-[1.5rem] bg-red-500 w-6 h-6 rounded-full text-white">
                                {cartItems && cartItems.length}
                            </p>
                        </Link>
                        <Menu as="div" className="relative w-full">
                            <Menu.Button className="inline-flex w-full my-5 md:my-1 justify-center text-sm font-medium  focus:outline-none">
                                {user && user.photoUrl ?
                                    <img onClick={() => setShowBar(!showBar)} src={user.photoUrl} className="w-10 object-cover h-10 cursor-pointer rounded-full shadow" alt="logo" />
                                    :
                                    <UserCircleIcon onClick={() => setShowBar(!showBar)} className="w-8 h-8 mr-5 text-white cursor-pointer" />
                                }
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute p-2 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="px-1 py-1 ">
                                        {nav.map((option, index) => (
                                            <Menu.Item key={index}>
                                                {({ active }) => (
                                                    <a href={option.href}
                                                        onClick={option.onClick}
                                                        className={classNames(
                                                            'group flex w-full  cursor-pointer items-center rounded-md px-2 py-3 text-sm',
                                                            active
                                                                ? 'bg-gray-100 text-gray-900'
                                                                : 'text-gray-900',
                                                        )}
                                                    >
                                                        <option.icon className="w-5 h-5 mr-2" />
                                                        {option.title}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>

                </div >
            </div>
            {children}
        </div>
    )
} 