import { ArrowLeftIcon, StarIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "../helper/ClassNames"
import { RadioGroup } from "@headlessui/react"
import { useProduct } from "../context/productContext"
import Radio from "./forms/RadioGroup"
import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

export default function Filter({ path, setfilter, mobile = false }) {

    const { filterFunction } = useProduct()
    const location = useLocation()
    const [filter, setFilter] = useState({
        rating: 0,
        offer: '',
        price: ''
    })

    const handleFilter = (e) => {
        const { name, value } = e.target
        setFilter((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const review = [4, 3, 2, 1]
    const discount = ["40%", "50%", "60%", "30%"]
    const price = ['below 1000', '1000-5000', '5000 - 10000', 'above 10000']

    const applyFilter = () => {
        setfilter(true)
        filterFunction(filter)
    }

    const clearFilter = () => {
        setFilter((prev) => ({
            ...prev,
            rating: 0,
            offer: '',
            price: ''
        }))
        setfilter(false)
    }

    const handleDiscount = (data) => {
        handleFilter({
            target: {
                name: 'offer',
                value: data,
            },
        });
    };

    const handlePrice = (data) => {
        handleFilter({
            target: {
                name: 'price',
                value: data
            }
        })
    }
    const handleRating = (data) => {
        handleFilter({
            target: {
                name: 'rating',
                value: data
            }
        })
    }


    return (
        <div className="w-full">
            <Link to='/' className="flex items-center gap-1 py-4 text-xs cursor-pointer hover:text-blue-600 "><ArrowLeftIcon className="w-4 h-4" />Back to Home</Link>
            {
                (filter.rating !== 0 || filter.offer !== '' || filter.price !== '') &&
                <div className="flex w-full gap-5 my-3">
                    <Link to={location.pathname}>
                        <button onClick={clearFilter} className="text-xs bg-gray-100 rounded-md px-5 mx-auto border border-gray-300  py-2 text-gray-800">Clear filter</button>
                    </Link>
                    <Link to={`${location.pathname}?filter=${path}`}>
                        <button onClick={applyFilter} className="text-xs bg-gray-800 rounded-md px-5 mx-auto py-2 text-white">Apply filter</button>
                    </Link>
                </div>
            }
            <h1 className="text-md font-medium pt-4 text-gray-900" >Catagorie</h1>
            <p className="text-sm py-1 capitalize">{path}</p>
            <div className={classNames(mobile ? 'grid grid-cols-1 w-full' : "")}>
                <Disclosure defaultOpen={true} as="div" className='my-1 relative'>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={classNames("w-full my-2", open && " border-b-2 border-gray-100")}>
                                <div className="flex justify-between py-3 w-full items-center">
                                    <h2>Customer Review</h2>
                                    <ChevronDownIcon className={classNames("w-5 h-5 mx-3", open ? "rotate-180" : '')} />
                                </div>
                            </Disclosure.Button >
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel as="div" className={classNames('px-3')}>
                                    <RadioGroup value={filter.rating} onChange={handleRating}>
                                        <div className="bg-white rounded-md -space-y-px">
                                            {review.map((setting, settingIdx) => (
                                                <RadioGroup.Option
                                                    key={setting}
                                                    value={setting}
                                                    className={({ checked }) =>
                                                        classNames(
                                                            settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                            settingIdx === review.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                            checked ? 'border-indigo-200' : 'border-gray-200',
                                                            'relative  py-3  flex cursor-pointer focus:outline-none'
                                                        )
                                                    }
                                                >
                                                    {({ active, checked }) => (
                                                        <>
                                                            <span
                                                                className={classNames(
                                                                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                                                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                            </span>
                                                            <div className="ml-3 flex flex-col">

                                                                <RadioGroup.Label
                                                                    as="span"
                                                                    className={classNames(checked ? 'text-blue-800' : 'text-gray-900', 'block text-sm')}
                                                                >
                                                                    <div className="flex items-center">
                                                                        {[0, 1, 2, 3, 4].map((rating) => (
                                                                            <StarIcon
                                                                                key={rating}
                                                                                className={classNames(
                                                                                    setting > rating ? 'text-yellow-400' : 'text-gray-300',
                                                                                    'h-5 w-5 flex-shrink-0'
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                        ))}
                                                                        {/* <span className="text-xs text-gray-700 ml-1"> & upto</span> */}
                                                                    </div>
                                                                </RadioGroup.Label>

                                                            </div>
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" defaultOpen={true} className='my-1'>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={classNames("w-full my-2", open && " border-b-2 border-gray-100")}>
                                <div className="flex justify-between py-3 w-full items-center">
                                    <h2>Discount</h2>
                                    <ChevronDownIcon className={classNames("w-5 h-5 mx-3", open ? "rotate-180" : '')} />
                                </div>
                            </Disclosure.Button >
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel as="div" className="px-3">
                                    <Radio value={filter.offer} handle={handleDiscount} option={discount} />

                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" defaultOpen={true} className='my-1'>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={classNames("w-full my-2", open && " border-b-2 border-gray-100")}>
                                <div className="flex justify-between py-3 w-full items-center">
                                    <h2>Price</h2>
                                    <ChevronDownIcon className={classNames("w-5 h-5 mx-3", open ? "rotate-180" : '')} />
                                </div>
                            </Disclosure.Button >
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel as="div" className="px-3">
                                    <Radio value={filter.price} handle={handlePrice} option={price} />
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>
        </div >
    )
}