import { useAuth } from "./authContext"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"


export const useAuthUser = () => {
    const auth = useAuth()
    const push = useNavigate()

    useEffect(() => {
        if (auth) {
            if (!auth.user) {
                push('/login')
            }
            if (auth.user && !auth.user.isUser) {
                push("/email-verify")
            }
            // if(auth.user && auth.user.admin){
            //     push("/admin")
            // }
        }
    }, [auth, push])

    return auth;
}
