import { useEffect } from "react"
import { useAuth } from "../../context/authContext"
import { useNavigate } from "react-router-dom"
import { useAuthUser } from "../../context/isAuthUser"
import classNames from "../../helper/ClassNames"
export default function EmailVerify() {
    const { verifyEmail, emailLoad } = useAuth()
    const { user } = useAuthUser()
    const push = useNavigate()

    useEffect(() => {
        if (user && user.isUser) {
            push("/")
        }
    }, [user, push])

    if (!user) return null


    return (
        <div className="flex justify-center  overflow-hidden">
            {/* <Home /> */}
            <div className="fixed top-0 inset-0  backdrop-blur-lg  z-40"></div>
            <div className="mx-4 flex items-center justify-center absolute top-0 left-40rem mt-8 sm:mx-auto sm:w-full sm:max-w-md z-50">
                <div>
                    <div className="mt-24 text-center">
                        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                            Verify your e-mail address
                        </h2>
                    </div>
                    <div className="px-4 pt-8 mt-8 bg-white rounded-lg shadow-lg sm:px-10 text-gray-500">
                        Click the below verification button we have send  verification link in your email address
                        <hr className="mt-3" />
                        <div className="text-md text-gray-600 py-3  flex items-center flex-wrap gap-5 justify-center text-center">
                            <button
                                disabled={emailLoad}
                                onClick={() => verifyEmail({ id: user._id })}
                                className={classNames('text-white w-full font-medium rounded-md py-3 px-10 mb-3 font-semibold ',
                                    emailLoad ? 'opacity-8 bg-gray-500 cursor-default' : 'bg-gray-700 cursor-pointer')}
                            >
                                {emailLoad ? "Loading..." : "Send verification link"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
} 