import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios"
import { toast } from "react-toastify";
import PageLoader from "../helper/LoadingPageConainter";
import { apiUrl } from "../helper/BaseUrl";

const authContext = createContext()
const { Provider } = authContext

export const useAuth = () => {
    return useContext(authContext)
}

export default function AuthProvider({ children }) {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadBtn, setLoadBtn] = useState({
        signup: false,
        login: false,
        password: false,
        forgetPassword: false,
        profile: false,
    })
    const [emailLoad, setEmailLoad] = useState(false)

    useEffect(() => {
        const accessUser = async () => {
            const refreshToken = localStorage.getItem("refreshToken")
            if (!refreshToken) {
                setLoading(false)
                console.log("user not login in")
            } else {
                const payload = {
                    token: refreshToken
                }
                await verifyUser(payload)
            }
        }
        accessUser()
    }, [])


    const verifyUser = async (payload) => {
        try {
            const res = await axios.post(`${apiUrl}/auth/access`, payload)
            if (res.data.message === "ok") {
                localStorage.setItem("token", res.data.user.accessToken)
                setUser(res.data.user)
            } else {
                const errorMessage = res.data.message
                toast.error(errorMessage);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'An error occurred';
            toast.error(errorMessage);
        } finally {
            setLoading(false)
        }
    }

    const registration = async (payload) => {
        try {
            setLoadBtn((prev) => ({ ...prev, signup: true }))
            const res = await axios.post(`${apiUrl}/auth/register`, payload)
            if (res.data.message === "ok") {
                toast.success(res.data.data)
                setLoadBtn((prev) => ({ ...prev, signup: false }))
                return res;
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'An error occurred';
            toast.error(errorMessage);
            setLoadBtn((prev) => ({ ...prev, signup: false }))

        }
    }
    const loginUser = async (payload) => {
        try {
            setLoadBtn((prev) => ({ ...prev, login: true }))
            const res = await axios.post(`${apiUrl}/auth/login`, payload)
            if (res.data.message === "ok") {
                localStorage.setItem("token", res.data.data.accessToken)
                localStorage.setItem("refreshToken", res.data.data.refreshToken)
                setUser(res.data.data)
                toast.success("login success")
                setLoadBtn((prev) => ({ ...prev, login: false }))
            }
        } catch (error) {
            // console.log(error);
            const errorMessage = error.response?.data?.error || 'An error occurred';
            toast.error(errorMessage);
            setLoadBtn((prev) => ({ ...prev, login: false }))
        }
    }

    const logout = () => {
        try {
            localStorage.removeItem("refreshToken")
            localStorage.removeItem('token')
            setUser(null)
            toast.success("Logout successfully")
        } catch (error) {
            toast.error(error.response.data.error || 'An error occurred')
        }
    }

    const resetLink = async (payload) => {
        try {
            setLoadBtn((prev) => ({ ...prev, forgetPassword: true }))
            const res = await axios.post(`${apiUrl}/auth/reset`, payload)
            if (res.data.message === "ok") {
                toast.success("reset link send success")
                setLoadBtn((prev) => ({ ...prev, forgetPassword: false }))
            }

        } catch (error) {
            toast.error(error.response.data.error || 'An error occurred')
            setLoadBtn((prev) => ({ ...prev, forgetPassword: false }))
        }
    }

    const submitPassword = async (token, password) => {
        try {
            setLoadBtn((prev) => ({ ...prev, password: true }))
            const res = await axios.post(`${apiUrl}/auth/resetPass?token=${token}`, password)
            if (res.data.message === "ok") {
                toast.success("Password update successfully")
            }
        } catch (error) {
            toast.error(error.response.data.error || 'An error occurred')
        } finally {
            setLoadBtn((prev) => ({ ...prev, forgetPassword: false }))
        }
    }

    const uploadImage = async (file, id) => {
        try {
            setLoadBtn((prev) => ({ ...prev, profile: true }))
            const res = await axios.post(`${apiUrl}/upload/${id}`, file)
            if (res.data.message === 'ok') {
                setUser(res.data.data)
                toast.success("Image upload successfully")
                setLoadBtn((prev) => ({ ...prev, profile: false }))
            }

        } catch (error) {
            toast.error(error.response.data.error || "Upload image  failed")
            setLoadBtn((prev) => ({ ...prev, profile: false }))
        }
    }

    const verifyEmail = async (id) => {
        try {
            setEmailLoad(true)
            const res = await axios.post(`${apiUrl}/auth/email-verify`, id)
            if (res.data.message === 'ok') {
                toast.success(res.data.data)
                setEmailLoad(false)
            }
        } catch (error) {
            setEmailLoad(false)
            toast.error(error.response.data.error || 'An error occurred')
        }
    }

    const confirmMail = async (token) => {
        try {
            const res = await axios.get(`${apiUrl}/auth/confirm/?token=${token}`)
            if (res.data.message === "ok") {
                setUser(res.data.data)
                toast.success("E-mail verified succesfully")
            }
        } catch (error) {
            toast.error(error.response.data.error || 'An error occurred')

        }
    }


    const updateUser = async (payload, id) => {
        try {
            setLoadBtn((prev) => ({ ...prev, profile: true }))
            const res = await axios.put(`${apiUrl}/updateUser/${id}`, payload)
            if (res.data.message === "ok") {
                setUser(res.data.data)
            }
        } catch (error) {
            toast.error(error.response.data.error || 'Error occured')
        } finally {
            setLoadBtn((prev) => ({ ...prev, profile: false }))
        }
    }
    const value = {
        registration,
        loginUser,
        user,
        loading,
        logout,
        resetLink,
        submitPassword,
        uploadImage,
        loadBtn,
        setUser,
        verifyEmail,
        confirmMail,
        emailLoad,
        updateUser,
    }

    return <Provider value={value}>{loading ?
        <PageLoader /> : children}</Provider>
}