// import NotFound from "../../asset/images/product.png"

export default function PageNot() {
    return (
        <div className="text-center w-full h-[90vh] flex items-center mt-10 justify-center">
            <div></div>
            <div className="w-full flex flex-col items-center justify-center">
                {/* <img className="w-[50%] mx-auto h-[50vh]" src={NotFound} alt="logo" /> */}
                <h1 className="text-4xl my-5 font-semibold">Page Not Found</h1>
                <h1 className="text-4xl font-semibold">404</h1>
            </div>

        </div>
    )
}