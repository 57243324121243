import React from "react"
import { useAuthUser } from "../../context/isAuthUser";


const OrderDetails = () => {
    const {user} = useAuthUser()
    console.log(user.orderProducts)
  return (
    <div className="flex h-[100vh] justify-center items-center">
       <p>Upcomeing soon...</p>
        <p></p>
    </div>
  )

}

export default OrderDetails;