import { BrowserRouter, Route, Routes } from "react-router-dom"
import Registration from "./pages/auth/Registration";
import Login from "./pages/auth/Login";
import Reset from "./pages/auth/ResetPassword";
import ResetPass from "./pages/auth/ForgetPassword";
import { navigation } from "./utils/navigation";
import NotFound from "./pages/404page/PageNotFound";

export default function Main() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {navigation.map((nav, index) => (
                        <Route key={index} path={nav.path} element={<nav.element />} />
                    ))}
                    <Route path="login" element={<Login />}></Route>
                    <Route path="sign-up" element={<Registration />}></Route>
                    <Route path="reset-password" element={<Reset />} />
                    <Route path="password" element={<ResetPass />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}
