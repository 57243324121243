import React from "react";
import { CheckCircleIcon, CircleStackIcon, ShoppingBagIcon, UserGroupIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAdmin } from "../../context/admin";
import AdminLayout from "../../component/admin/AdminLayout";
import { Link, useLocation } from "react-router-dom";
import { useAuthUser } from "../../context/isAuthUser";

export default function Dashboard() {
  const { admin } = useAdmin()
  const location = useLocation()
  const { user } = useAuthUser()
  const users = admin?.data?.data?.users
  const product = admin?.data?.data?.product

  const verifiedUser = users?.filter((users) => users.isUser)
  const notVerifyUser = users?.filter((users) => !users.isUser)

   if(!user) return null;

  return (
    <AdminLayout path={location.pathname.substring(1)}>
      <div className="max-w-5xl py-4 rounded-md mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <div className="grid grid-cols-6 gap-4 my-5">
          <div className="col-span-2 relative border px-6 pt-5 pb-4 shadow-md rounded-md">
            <h2 className="text-md font-medium text-gray-700 pb-2 mb-3">User Details</h2>
            <div className="flex items-center gap-2">
              <UserGroupIcon className="w-5 h-5" />
              <p className="text-sm text-gray-800">Total Users</p>
            </div>
            <p className="text-xs text-gray-500 py-3 pl-7">{users?.length || 0} Users</p>
            <div className="flex items-center gap-2">
              <CheckCircleIcon className="w-5 h-5" />
              <p className="text-sm text-gray-800">G-mail verified users</p>
            </div>
            <p className="text-xs text-gray-500 py-3 pl-7">{verifiedUser?.length || 0} Users</p>
            <div className="flex items-center gap-2">
              <XCircleIcon className="w-5 h-5" />
              <p className="text-sm text-gray-800">G-mail not verified users</p>
            </div>
            <p className="text-xs text-gray-500 py-3 pl-7">{notVerifyUser?.length || 0} Users</p>
            <Link to="/user-detail" className="text-xs text-end text-blue-700 absolute bottom-3 right-3 cursor-pointer">View Details</Link>
          </div>
          <div className="col-span-2 border px-6 pt-5 pb-4 relative shadow-md rounded-md">
            <h2 className="text-md font-medium text-gray-700 pb-2 mb-3">Order Details</h2>
            <div className="flex items-center gap-2">
              <ShoppingBagIcon className="w-5 h-5" />
              <p className="text-sm text-gray-800">Total Orders</p>
            </div>
            <p className="text-xs text-gray-500 py-3 pl-7">0 Orders</p>
            <Link to="/user-detail" className="text-xs text-end text-blue-700 absolute bottom-3 right-3 cursor-pointer">View Details</Link>
          </div>
          <div className="col-span-2  border px-6 pt-5 pb-4 relative shadow-md rounded-md">
            <h2 className="text-md font-medium text-gray-700 pb-2 mb-3">Product Details</h2>
            <div className="flex items-center gap-2">
              <CircleStackIcon className="w-5 h-5" />
              <p className="text-sm text-gray-800">Total Products</p>
            </div>
            <p className="text-xs text-gray-500 py-3 pl-7">{product?.length || 0} Products</p>
            <Link to="/product-detail" className="text-xs text-end text-blue-700 absolute bottom-3 right-3 cursor-pointer">View Details</Link>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}