import { Link, useParams } from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { CurrencyDollarIcon, GiftIcon, LockClosedIcon, TrophyIcon, TruckIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Select from "../component/Select";
import Review from "../helper/StarReview";
import { useProduct } from "../context/productContext";
import { StarIcon } from "@heroicons/react/24/solid";
import classNames from "../helper/ClassNames";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../helper/BtnSpinner";
import { useAuthUser } from "../context/isAuthUser";
import Header from "../component/Header";

const service = [
    {
        name: "Free delivery",
        icon: TruckIcon
    },
    {
        name: "Pay on delivery",
        icon: CurrencyDollarIcon
    },
    {
        name: "Top Brand",
        icon: TrophyIcon
    },
    {
        name: "Gifts",
        icon: GiftIcon
    },
    {
        name: "Secured",
        icon: LockClosedIcon
    }
]
const quantity = [
    {
        name: "1",
        value: 1
    },
    {
        name: "2",
        value: 2
    },
    {
        name: "3",
        value: 3
    },
    {
        name: "4",
        value: 4
    },
    {
        name: "5",
        value: 5
    },
    {
        name: "6",
        value: 6
    },
    {
        name: "7",
        value: 7
    },
    {
        name: "8",
        value: 8
    },
    {
        name: "9",
        value: 9
    },
    {
        name: "10",
        value: 10
    },


]
export default function SinglePruduct() {
    const { user } = useAuthUser()
    const { product, review, addProductToCart, loadBtn } = useProduct()
    const getId = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const path = searchParams.get('path');
    const [quant, setquant] = useState(quantity[0])
    const [reviewForm, setReviewFrom] = useState(false)
    const [storeRview, setStoreReview] = useState({
        review: "",
        rating: 0,
        userId: user && user._id,
        productId: getId.id,
        photoUrl: user && user.photoUrl,
        userName: user && user.name
    })

    const [ads, setAds] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setAds(true)
        }, 500)
    }, [])

    const single = product.data.filter((item) => {
        if (item._id === getId.id) {
            return item
        }
        return false;
    })

    const handleAddStar = (index) => {
        setStoreReview((prev) => ({
            ...prev,
            rating: index + 1
        }))
    }
    // const handleRemoveStar = () => {
    //     setStoreReview((prev) => ({
    //         ...prev,
    //         rating: storeRview.rating - 1
    //     }))
    // }

    const handleReview = (e) => {
        const { name, value } = e.target
        setStoreReview((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmitReview = async () => {
        if (storeRview.review !== '' && storeRview.rating !== 0) {
            await review(storeRview)
            setStoreReview({
                review: "", rating: '', userId: user && user._id,
                productId: getId.id,
                photoUrl: user && user.photoUrl,
                userName: user && user.name
            })
        } else {
            toast.error('Please add your Review')
        }
    }

    const handleAddCart = () => {
        if (user._id && getId) {
            const data = {
                userId: user._id,
                productId: getId.id,
                quantity: quant.value
            }
            addProductToCart(data)
        }
    }

    if (!user) return null;

    return (
        <Header>
        <div className="px-2 md:px-10">
            {path ?
                <Link to={`/${path}`} className="text-xs text-gray-400 py-3"> {"<"} Back to Result</Link>
                : <Link to={`/`} className="text-xs text-gray-400 py-3"> {"<"} Back to Result</Link>
            }
            {single.map((item, index) => (
                <div key={index}>
                    <h2 className="text-xl font-semibold text-gray-800 py-3">{item.brandName}</h2>
                    <div className="flex gap-5 flex-wrap md:flex-nowrap md:gap-10">
                        <div className="w-full md:w-[30%]">
                            <PhotoProvider>
                                <PhotoView src={item.image}>
                                    <img className="rounded-lg shadow-lg w-full cursor-pointer" src={item.image} alt="logo" />
                                </PhotoView>
                                <p className="text-xs text-gray-600 text-center py-3">Click img zoom in</p>
                            </PhotoProvider>
                            {ads && (
                                <>
                                    <h3 className="text-lg text-gray-700 font-semibold">Product Infromation</h3>

                                    <div className="shadow-lg w-full  h-fit px-3 py-4 rounded-lg mb-5 ">
                                        <h3 className="text-md text-center text-gray-700 font-semibold">SubTotal</h3>
                                        <p className="text-md text-center  text-green-700">${item.price * quant.value}.00</p>
                                        <p className="text-xs w-full text-center px-3 md:px-10 text-gray-700">Your order is <strong className="font-bold">{(item.price >= 100 || item.price * quant.value >= 100) ? "eligible" : "Not eligible"}</strong> for FREE Delivery. Select this option at checkout.</p>
                                        {(item.price >= 100 || item.price * quant.value >= 100) ?
                                            ""
                                            :
                                            <p className="text-xs text-center w-full mx-auto p-4 rounded-sm my-2 text-yellow-700">Below $100 home delivery is not eligible</p>
                                        }
                                        <img className="rounded-sm mx-auto shadow-lg w-40 my-3 cursor-pointer" src={item.image} alt="logo" />
                                        <p className="text-xs text-center">${item.price}.00</p>
                                        <div className="flex justify-center my-3 gap-3 items-center">
                                            <p className="text-sm text-gray-700">Quantity:</p>
                                            <Select selected={quant} setSelected={setquant} options={quantity} />
                                        </div>
                                        <div className="flex items-center justify-between gap-10">
                                            {/* <button className="bg-gray-700 my-6 text-sm w-full  mx-auto text-white py-3  shadow-lg hover:bg-gray-600 rounded-full">Buy Now</button> */}
                                            <Spinner
                                                title="Add to Cart"
                                                loading={loadBtn}
                                                loadingTitle="Adding item..."
                                                handleFn={handleAddCart}

                                            />
                                            {/* {loadBtn ? <Spinner title="Add item..." /> :
                                                <button onClick={handleAddCart} className="bg-gray-700 text-sm w-full  mx-auto text-white py-3  shadow-lg hover:bg-gray-600 rounded-full">Add to Cart</button>
                                            } */}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-full md:w-[50%]">
                            <div className="shadow-md px-3 py-4 rounded-sm ">
                                <div className="">
                                    <p className="text-xl font-semibold text-gray-900">${item.price} <span className="text-sm line-through font-normal"> {item.discountPrice} </span></p>
                                    <span className="text-xs text-white bg-black px-2 py-1">Up to <strong className="text-md text-white">{item.offer}</strong></span>
                                    <h3 className="text-gray-700  pt-3 font-normal text-sm">{item.brandName}</h3>
                                    <p className="text-sm text-gray-500 pt-2">{item.delivery}</p>
                                </div>
                                <div className="flex justify-start flex-wrap lg:flex-nowrap">
                                    {service.map((item, idx) => (
                                        <div key={idx} className="bg-white shadow-md w-24 rounded-lg mx-3 my-5 py-4 px-1">
                                            <item.icon className="w-6 h-6 mx-auto" />
                                            <p className="text-xs text-gray-700 pt-3 text-center">{item.name}</p>
                                        </div>
                                    ))}
                                </div>
                                <h3 className="text-md text-gray-700 font-semibold mt-2 p-3">Product</h3>
                                <div className="bg-white w-28 lex-wrap flex flex-wrap lg:flex-nowrap gap-5 mx-3">
                                    <img className="rounded-sm shadow-lg  cursor-pointer" src={item.image} alt="logo" />
                                    <img className="rounded-sm shadow-lg  cursor-pointer opacity-40" src={item.image} alt="logo" />
                                    <img className="rounded-sm shadow-lg  cursor-pointer opacity-30" src={item.image} alt="logo" />
                                </div>
                                <h3 className="text-md text-gray-700 font-semibold mt-2 p-3">About this item</h3>
                                <p className="text-xs text-gray-500 px-3 py-1">1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p className="text-xs text-gray-500 px-3 py-1">2. Cing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            {item.review.length !== 0 ?
                                <>
                                    <h3 className="text-md text-gray-700  my-5 font-semibold">Review</h3>
                                    <Review data={item.review} />
                                </>
                                :
                                <div className="flex items-center pt-10 justify-center">
                                    <div className="text-center">
                                        <h3 className="text-md font-bold text-gray-600">Not Review Found</h3>
                                        <p className="text-sm text-gray-500">please write your first review</p>
                                        {/* <button onClick={() => setReviewFrom(true)} className="shadow-md py-3 px-2  focus:outline-none  focus:ring-1 focus:ring-gray-400  rounded-md my-3 bg-white hover:bg-gray-50  hover:shadow-sm text-sm text-gray-600 text-center cursor-pointer">Click this button</button> */}
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="text-center w-full md:w-fit">
                            <h3 className="text-md text-gray-700 font-semibold">Review this product</h3>
                            <p className="text-xs text-gray-400 py-1">Share your thoughts with other customers</p>
                            <p onClick={() => setReviewFrom(true)} className="shadow-md py-3 px-2  focus:outline-none  focus:ring-1 focus:ring-gray-400  rounded-md my-3 bg-white hover:bg-gray-50  hover:shadow-sm text-sm text-gray-600 text-center cursor-pointer">Write a product review</p>
                            {reviewForm && (
                                <div className="border-t broder-gray-200 mt-10">
                                    <textarea
                                        type="text"
                                        name="review"
                                        placeholder="Enter your review"
                                        value={storeRview.review}
                                        onChange={handleReview}
                                        className="border-2 border-gray-300 text-gray-700 text-sm rounded-md  focus:outline-none  focus:ring-1 focus:ring-gray-400 py-1 px-3 w-full"
                                    />
                                    <h3 className="text-md md:text-start text-gray-700 pt-3 font-semibold">Star rating</h3>
                                    <div className="flex justify-center md:justify-start  gap-4 items-center py-2 cursor-pointer ">
                                        {/* <p onClick={handleRemoveStar} className="text-lg  cursor-pointer pr-5">-</p> */}
                                        {[0, 1, 2, 3, 4].map((rating, index) => (
                                            <StarIcon
                                                key={rating}
                                                onClick={() => handleAddStar(index)}
                                                className={classNames(
                                                    storeRview.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                                    'h-5 w-5 flex-shrink-0 '
                                                )}
                                                aria-hidden="true"
                                            />
                                        ))}
                                        {/* <p onClick={handleAddStar} className="text-lg  cursor-pointer cursor-pointer pl-5">+</p> */}
                                    </div>
                                    <button onClick={onSubmitReview} className="bg-gray-800 text-xs  my-3 text-white py-2 px-3 w-full  shadow-lg hover:bg-gray-600 rounded-md">Submit</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))
            }

        </div >
        </Header>
    )
}