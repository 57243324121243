import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "./authContext";
import { apiUrl } from "../helper/BaseUrl";


const productContext = createContext()
const { Provider } = productContext

export const useProduct = () => {
    return useContext(productContext)
}

export default function ProductProvider({ children }) {
    const token = localStorage.getItem("token");

    const config = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`
        }
    }), [token]);



    const [product, setProduct] = useState({
        loading: false,
        data: []
    })
    const [filterData, setFilterData] = useState({
        loading: false,
        data: []
    })
    const [orderItem, setOrderItem] = useState({
        loading: false,
        data: {}
    })
    const [cartItems, setCartItems] = useState([])
    const [searchData, setSearchData] = useState([])
    const [loadBtn, setLoadBtn] = useState(false)
    const [fetchCartItems, setFetchCartItems] = useState(false)
    const { user } = useAuth();

    useEffect(() => {
        const getProduct = async () => {
            try {
                const token = localStorage.getItem("token")
                if (token) {
                    setProduct((prev) => ({ ...prev, loading: true }))
                    const res = await axios.get(`${apiUrl}/products`, config)
                    if (res) {
                        setProduct((prev) => ({ ...prev, data: res.data.data, loading: false }))

                    }
                }
            } catch (error) {
                const errorMessage = error.response?.data?.error || 'An error occurred';
                toast.error(errorMessage);
                setProduct((prev) => ({ ...prev, loading: false }))
            }
        }
        if (user) {
            getProduct()
        }
    }, [config, user])

    useEffect(() => {
        const getcartItems = async (payload) => {
            try {
                // setLoadBtn(true)
                const res = await axios.post(`${apiUrl}/cart-items`, payload)
                if (res.data.status) {
                    setCartItems(res.data.data)
                    setFetchCartItems(true)
                    // setLoadBtn(false)
                    // toast.success(res.data.message)
                }
            } catch (error) {
                setFetchCartItems(true)
                // toast.error(error.response.data.error || "error occured")
                // setLoadBtn(false)
            }
        }
        if (user) {
            const data = {
                userId: user._id
            }
            getcartItems(data)
        }
    }, [user])


    useEffect(() => {
        const getOrderItems = async (payload) => {
            try {
                setOrderItem((prev) => ({
                    ...prev,
                    loading: true,
                }))
                const res = await axios.post(`${apiUrl}/order-items`, payload)
                if (res.data.status) {
                    setOrderItem((prev) => ({
                        ...prev,
                        loading: false,
                        data: res.data.data
                    }))
                }
            } catch (error) {
                setOrderItem((prev) => ({
                    ...prev,
                    loading: false,
                }))
                toast.error(error.response.data.error || "error occured")
            }
        }
        if (user) {
            const data = {
                userId: user._id
            }
            getOrderItems(data)
        }
    }, [user])



    const review = async (review) => {
        try {
            const res = await axios.post(`${apiUrl}/review`, review, config)

            if (res.data.message === "ok") {
                const updatedProduct = product.data.map((item) => {
                    if (item._id === res.data.data._id) {
                        return res.data.data;
                    }
                    return item;
                });
                setProduct((prev) => ({
                    ...prev,
                    data: updatedProduct
                }))
                toast.success("successfully submit review");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'An error occurred';
            toast.error(errorMessage);
        }
    }

    const addProductToCart = async (data) => {
        try {
            setLoadBtn(true)
            const res = await axios.post(`${apiUrl}/addCart`, data, config)
            if (res.data.status) {
                setCartItems([...cartItems, res.data.data])
                setLoadBtn(false)
                toast.success(res.data.message)
            }
        } catch (error) {

            toast.error(error.response.data.error || "error occured")
            setLoadBtn(false)
        }
    }

    const removeItem = async (id) => {
        try {
            const res = await axios.post(`${apiUrl}/removeCart`, id, config)
            if (res.data.status) {
                setCartItems(res.data.data)
            }
        } catch (error) {
            toast.error(error.response.data.error || "error occured")
        }
    }

    // const removeNotification = async (payload) => {
    //     try {
    //         const res = await axios.post(`${apiUrl}/remove-notification`, payload)
    //         if (res.data.message === "ok") {
    //             setUser(res.data.data)
    //         }
    //     } catch (error) {
    //         toast.error(error.response.data.error || "error occured")
    //     }
    // }

    const filterFunction = async (filterData) => {
        try {
            setFilterData((prev) => ({ ...prev, loading: true }))
            if (filterData.rating !== 0 || filterData.offer !== '' || filterData.price !== '') {
                const res = await axios.get(`${apiUrl}/filter?rating=${filterData.rating}&offer=${filterData.offer}&price=${filterData.price}`, config)
                setFilterData((prev) => ({ ...prev, data: res.data.data, loading: false }))
            }
        } catch (error) {
            toast.error(error.response.data.error || "error occured")
            setFilterData((prev) => ({ ...prev, loading: false }))
        }
    }

    const searchFilter = async (search) => {
        try {
            if (search) {
                const res = await axios.get(`${apiUrl}/search?q=${search}`, {}, config)
                setSearchData(res.data.data)
            }
        } catch (error) {
            toast.error(error.response.data.error || "error occured")
        }
    }

    const value = {
        product,
        review,
        addProductToCart,
        loadBtn,
        removeItem,
        filterFunction,
        filterData,
        searchFilter,
        searchData,
        cartItems,
        fetchCartItems,
        setCartItems,
        orderItem
    }

    return <Provider value={value}>{children}</Provider>
}