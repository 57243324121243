import { Link } from "react-router-dom"
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline"
import { useProduct } from "../context/productContext"
import NotFound from "../asset/images/product.png"
import { useAuthUser } from "../context/isAuthUser"
import { usePayment } from "../context/paymentContext"
import Spinner from "../helper/BtnSpinner"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Header from "../component/Header"
import { useEffect, useState } from "react"
import PageLoader from "../helper/LoadingPageConainter"


export default function UserCart() {
    const { loading, razoryPayment, paymentEvent } = usePayment()
    const { user } = useAuthUser();
    const { removeItem, cartItems, product, fetchCartItems
    } = useProduct()
    const [cart, setCart] = useState([])
    const push = useNavigate();



    console.log(user, 'user dat')

    useEffect(() => {
        if (paymentEvent.success) {
            push("/payment-success")
        } else if (paymentEvent.faild) {
            push('/payment-failed')
        }
    }, [paymentEvent, push])

    useEffect(() => {
        if (cartItems.length !== 0) {
            const filteredCart = product.data.filter((item1, i) => {
                return cartItems.find((item) => item.productId === item1._id)
            })
            setCart(filteredCart)

        } else {
            setCart([])
        }
    }, [cartItems, product])



    const handleRemoveItem = (id) => {
        const ids = {
            userId: user && user._id,
            productId: id
        }
        removeItem(ids)
    }

    var quantityMap = {};
    cartItems.forEach(item => {
        quantityMap[item.productId] = item.quantity;
    });

    cart.forEach(item => {
        item.quantity = quantityMap[item._id];
    });

    let sum = 0;
    const totalPrice = () => {
        cart.map((item) => {
            return sum += (item.price * item.quantity)
        })
    }
    totalPrice()

    // const shippingFee = () => {
    //     if (sum <= 500) {
    //         return "free"
    //     }
    //     else if (sum > 500 && sum <= 1000) {
    //         return 30
    //     } else if (sum > 1000 && sum <= 5000) {
    //         return 100
    //     } else if (sum > 5000 && sum <= 10000) {
    //         return 150
    //     } else {
    //         return 200
    //     }
    // }

    const totalItems = [
        {
            title: 'Total Itmes',
            price: `(${cart && cart.length})`
        },
        {
            title: 'Total Price',
            price: `$ ${sum}.00`
        },
        {
            title: 'Shipping Fee',
            price: `Free Delivery`
        }
    ]
    const totalAmount = sum

    const handlePayment = async () => {
        const payload = {
            id: user && user._id,
            email: user && user.email,
            items: cart && cart
        }
        if (user.address) {
            await razoryPayment(payload)

        } else {
            toast.error("Please Fill Address Details")
            push("/profile")
        }
    }



    if (!user) return null;
    if (!fetchCartItems) return <PageLoader />
    return (
        <>
            <Header>
                {user && cart.length !== 0 ?
                    <div className="lg:mx-20 py-4 md:mt-3 flex gap-2 md:direction-row flex-wrap xl:justify-evenly xl:flex-nowrap items-start rounded-lg bg-white">

                        <div>
                            <Link to='/' className="border mb-5 focus:ring ml-8 focus:ring-gray-300  focus:ring-offset-4 px-5 py-2 rounded-md flex w-fit items-center gap-2 text-sm hover:bg-gray-100 shadow"><ArrowLeftIcon className="w-4 h-4" /> Back to Home</Link>
                            {/* <h1 className="text-lg p-4 text-center font-semibold text-gray-700">Items({user.cart.length})</h1> */}
                            <div className="mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-3 cursor-pointer md:pb-10 overflow-hidden">
                                {
                                    cart.map((item, index) => (
                                        <div key={index} className="my-4 rounded-lg mx-4  flex gap-0 md:gap-3 shadow border border-gray-100">
                                            <div className="w-full  h-fit relative">
                                                <img className="w-full  h-[40%] rounded-t-lg object-cover" src={item.image} alt="logo" />
                                                <div className="px-3 py-2 w-full">
                                                    <h3 className="text-gray-700 line-clamp-2 py-1 sm:py-0 font-normal text-sm">{item.brandName}
                                                    </h3>
                                                    <p className="text-lg font-medium py-2 text-gray-900">${item.price}.00 <span className="text-sm line-through"> {item.discountPrice} </span></p>
                                                    <p className="text-sm font-medium pb-4 text-gray-700">Total Quantity : {item?.quantity} </p>
                                                    <span className="text-xs text-white bg-black px-2 py-1">Up to <strong className="text-md my-2 text-white">{item.offer}</strong></span>
                                                    <p className="text-sm text-gray-500 pt-2">{item.delivery}</p>
                                                    {/* <p className="text-sm text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p> */}
                                                    <Link to={`/product/${item._id}`} className="bg-gray-800 cursor-pointer leading-3 py-[9px] rounded-md block w-fit px-4 my-5 text-xs text-white w-full text-center">Checkout Now</Link>
                                                    <TrashIcon onClick={() => handleRemoveItem(item._id)} className="w-10 h-10 bg-white shadow-md rounded text-red-600 p-2 border  absolute top-5 right-5" />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="lg:max-h-[100vh] lg:h-auto sticky top-20">
                            <div className="shadow mx-10 w-[90%] md:80 my-4 border h-fit px-3 py-4 rounded-lg mb-5 ">
                                {totalItems.map((item, index) => (
                                    <div key={index} className="grid grid-cols-4 grid-gap-5 place-items-center">
                                        <div className="col-span-2 my-2">
                                            <p className="text-gray-800 text-md font-bold">{item.title} : </p>
                                        </div>
                                        <div className="col-span-2 my-2">
                                            <p className="text-grau-800 text-md">{item.price}</p>
                                        </div>

                                    </div>
                                ))}
                                <div className="flex  border-t border-gray-300 gap-16">
                                    <div className="pl-5 my-2">
                                        <p className="text-gray-800 text-md font-bold">Total Amount</p>
                                    </div>
                                    <div className="col-span-2 my-2">
                                        <p className="text-grau-800 text-md">${totalAmount}.00</p>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <Spinner
                                        title="Place Order"
                                        loading={loading}
                                        loadingTitle="Loading..."
                                        handleFn={handlePayment}

                                    />
                                </div>
                            </div>
                        </div >
                    </div>
                    :
                    <div className="flex items-center flex-col mt-4 justify-center text-center">
                        <img className="w-full md:w-[40%] h-auto" src={NotFound} alt="img" />
                        <h3 className="text-lg text-gray-700 font-medium mt-10">No items in your cart</h3>
                        <Link to="/" className="flex items-center gap-3 mt-5 border hover:bg-gray-100 shadow  text-gray-800 rounded-md px-4 py-2 text-sm">
                            <ArrowLeftIcon className="w-5 h-5" />
                            Back to home
                        </Link>
                    </div>
                }
            </Header>
        </>
    )
}