import Main from "./Main";
import AuthProvider from "./context/authContext";
import ProductProvider from "./context/productContext";
import PaymentProvider from "./context/paymentContext";
import AdmainProvider from "./context/admin";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div>
      <AuthProvider>
        <ProductProvider>
          <PaymentProvider>
            <AdmainProvider>
            <Main />
            </AdmainProvider>
          </PaymentProvider>
        </ProductProvider>
      </AuthProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        theme="light"
      />
    </div >
  );
}

export default App;
