import { useState } from "react";
import { useAuth } from "../../context/authContext";
import { toast } from "react-toastify";
import { EyeSlashIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../helper/BtnSpinner";


export default function ForgetPass() {
    const [resetPassword, setResetPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const push = useNavigate()
    const { submitPassword, loadBtn } = useAuth()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const handleReset = async () => {
        if (resetPassword.newPassword !== '' && resetPassword.confirmPassword !== '') {
            if (resetPassword.newPassword === resetPassword.confirmPassword) {
                const payload = {
                    password: resetPassword.confirmPassword
                }
                await submitPassword(token, payload)
                push("/login")
            } else {
                toast.error("Password not match")
            }
        } else {
            toast.error("Input fields required")
        }
    }
    const handlePassword = (e) => {
        const { name, value } = e.target
        setResetPassword((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className="flex items-center border justify-center h-[100vh] w-full">
            <div className="flex justify-between items-center">
                <div className='flex flex-col items-center justify-center h-[89vh]'>
                    <div className='shadow-md border rounded-lg bg-white py-6 px-8 m-3'>
                        <h2 className='text-gray-600 text-xl font-medium pb-6 text-center'>Edit Password</h2>
                        <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                            <EyeSlashIcon className='w-5 h-5 text-gray-400' />
                            <input type="password"
                                name='newPassword'
                                required
                                value={resetPassword.newPassword}
                                onChange={handlePassword}
                                placeholder='New Password'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                            <EyeSlashIcon className='w-5 h-5 text-gray-400' />
                            <input type="password"
                                name='confirmPassword'
                                required
                                value={resetPassword.confirmPassword}
                                onChange={handlePassword}
                                placeholder='Confirm Password'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <Spinner
                            title="Reset Password"
                            loading={loadBtn.password}
                            loadingTitle="Reseting..."
                            handleFn={handleReset}

                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
