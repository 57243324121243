
export const catagory = [
    {
        image: 'https://media.istockphoto.com/id/1465899163/photo/purple-and-green-headphones-fluttering-on-a-pink-background-creative-photo.jpg?s=2048x2048&w=is&k=20&c=lRIevFNZl-TO_A6hfVR0BZIOoTKmPH6YH4NsZkJj5WM=',
        brandName: "HeadPhone",
        offer: "40%",
        navLink: "headphone"
    },
    {
        image: 'https://media.istockphoto.com/id/1029147344/photo/video-archives-concept.jpg?s=2048x2048&w=is&k=20&c=7hUP6PCpCqVdRqT6EqKKRIC-v1YfePBD8oiPxkLIIVs=',
        brandName: "Mobile & Tablet",
        offer: "30%",
        navLink: "mobiles"


    },
    {
        image: 'https://media.istockphoto.com/id/512979895/photo/impressed-with-her-culinary-skills.jpg?s=1024x1024&w=is&k=20&c=7H9IYCAgWxVTM6zlBmEs57vfhjcQq4pVXzzOmMpyh1k=',
        brandName: "Home Appliances",
        offer: "60%",
        navLink: "homeappliance"

    },
    {
        image: 'https://images.unsplash.com/photo-1441984904996-e0b6ba687e04?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        brandName: "Dress",
        offer: "70%",
        navLink: "dress"
    },
]
