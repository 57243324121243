import Home from "../pages/Home";
import HeadPhone from "../pages/HeadPhone";
import SinglePruduct from "../pages/SigleProduct";
import HomeAppliance from "../pages/HomeAppliance";
import Mobiles from "../pages/Mobiles";
import Cart from "../pages/UserCart";
import UserProfile from "../pages/user/UserProfile";
import Confirmation from "../pages/auth/ConfirmMail";
import EmailVerify from "../pages/auth/EmailVerify";
import Dashboard from "../pages/admin/Dashboard";
import UserDetail from "../pages/admin/UserDetail";
import ProductDetails from "../pages/admin/ProductDetail";
import PaymentSuccess from "../pages/payment/Success";
import PaymentFailed from "../pages/payment/Failed";
import OrderDetails from "../pages/admin/Oders";
import UserOrder from "../pages/Orders";
import Dress from '../pages/DressPage'

export const navigation = [
    {
        path: "/",
        element: Home
    },
    {
        path: "headphone",
        element: HeadPhone
    },
    {
        path: "/product/:id",
        element: SinglePruduct
    }, {
        path: "homeappliance",
        element: HomeAppliance
    },
    {
        path: "mobiles",
        element: Mobiles
    },
    {
        path: "cart",
        element: Cart
    },
    {
        path: 'profile',
        element: UserProfile
    },
    {
        path: 'email-verify',
        element: EmailVerify
    },
    {
        path: 'email-confirmation',
        element: Confirmation
    },
    {
        path: "/admin",
        element: Dashboard
    },
    {
        path: "/user-detail",
        element: UserDetail
    },
    {
        path: "/product-detail",
        element: ProductDetails
    },
    {
        path: "/payment-success",
        element: PaymentSuccess
    },
    {
        path: '/payment-failed',
        element: PaymentFailed
    }, {
        path: '/orders',
        element: OrderDetails
    }, {
        path: "order-details",
        element: UserOrder
    },
    {
        path: "dress",
        element: Dress
    }
]
