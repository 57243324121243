import { UserIcon } from "@heroicons/react/24/outline"
import { useState } from "react";
import { useAuth } from "../../context/authContext";
import { toast } from "react-toastify";
import Spinner from "../../helper/BtnSpinner";

export default function ResetPass() {
    const [resetPassword, setResetPassword] = useState("")
    const { resetLink, loadBtn } = useAuth()

    const handleReset = () => {
        if (resetPassword) {
            const payload = {
                email: resetPassword
            }
            resetLink(payload)
        } else {
            toast.error("email field required")
        }
    }

    return (
        <div className="flex  items-center justify-center h-[100vh] w-full">
            <div className="flex justify-between items-center">
                <div className='flex flex-col items-center justify-center h-[89vh]'>
                    <div className='shadow-md border rounded-lg bg-white py-6 px-8 m-3'>
                        <h2 className='text-gray-600 text-xl font-medium pb-6 text-center'>Reset Password</h2>
                        <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                            <UserIcon className='w-5 h-5 text-gray-500' />
                            <input type="text"
                                name='name'
                                required
                                value={resetPassword}
                                onChange={(e) => setResetPassword(e.target.value)}
                                placeholder='Enter email'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <Spinner
                            title="Send Reset Link"
                            loading={loadBtn.forgetPassword}
                            loadingTitle="Sending link..."
                            handleFn={handleReset}

                        />
                        {/* <button onClick={handleReset} className='w-full text-white my-3 py-2 px-3 bg-blue-800  text-sm rounded-md'>Send Reset Link</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
