import Filter from "../component/Fillter"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import calssNames from "../helper/ClassNames"
import classNames from "../helper/ClassNames";
import { useLocation } from "react-router-dom";
import { StarIcon } from "@heroicons/react/24/solid";
import NotFound from "../asset/images/product.png"
import PageLoader from "../helper/LoadingPageConainter";

export default function CatagoriesPage({ data, filter }) {
    const location = useLocation()
    const [api, setApi] = useState([])
    const [onFilter, setOnFilter] = useState(false)

    useEffect(() => {
        setApi(data)
    }, [data])

    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage] = useState(15)

    const indexlr = currentPage * recordsPerPage

    const indexfr = indexlr - recordsPerPage

    const pagenationData = api.slice(indexfr, indexlr)

    const nPages = Math.ceil(api.length / recordsPerPage)

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
        if (pagenationData !== nPages)
            setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if (pagenationData !== 1)
            setCurrentPage(currentPage - 1)
    }

    if (pagenationData.length === 0 && onFilter !== true) {
        return < PageLoader />
    }

    return (
        <div className="flex mx-2 xl:mx-10 flex-wrap sm:flex-nowrap  gap-10 md:px-5 xl:h-[87vh]">
            <div className="md:w-[40%] lg:w-[20%]  md:block hidden md:block  lg:max-h-[100vh] lg:h-fit  sticky top-20 ">
                <Filter setfilter={setOnFilter} path={filter} mobile={true} />
            </div>
            <div className="md:hidden w-full">
                <Filter setfilter={setOnFilter} path={filter} mobile={true} />
            </div>

            {pagenationData.length !== 0 ?
                <div className="w-full max-w-7xl mx-auto mb-5">

                    <h3 className="text-lg text-gray-700 py-4 font-semibold">Best Products</h3>
                    <div className="grid grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-5 cursor-pointer">
                        {pagenationData.map((item, index) => (
                            <Link to={`/product/${item._id}?path=${location.pathname.substring(1)}`} key={index} className="shadow-md rounded-sm border border-gray-100">
                                <img className="rounded-sm h-[8rem] md:h-[10.5rem] object-cover w-full" src={item.image} alt="logo" />
                                <div className="px-3 py-4">
                                    <h3 className="text-gray-700 line-clamp-2 py-1 sm:py-0 font-normal text-sm">{item.brandName}
                                    </h3>
                                    <p className="text-lg text-gray-900">${item.price}.00 <span className="text-sm line-through"> {item.discountPrice} </span></p>
                                    <span className="text-xs text-white bg-black px-2 py-1">Up to <strong className="text-md text-white">{item.offer}</strong></span>
                                    {/* <p className="text-sm text-gray-500 pt-2">{item.delivery}</p> */}
                                    <div className="flex items-center mt-2">
                                        {[0, 1, 2, 3, 4].map((rating) => (
                                            <StarIcon
                                                key={rating}
                                                className={classNames(
                                                    item.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                                    'h-5 w-5 flex-shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                        ))}
                                        <p className="text-xs hidden md:block text-gray-700 pl-2"> {item.rating} of 5 star rating</p>
                                    </div>
                                </div>
                            </Link>
                        ))
                        }
                    </div>
                    <div className="flex items-center justify-center pt-10 pb-10">
                        <h3 className={calssNames('cursor-pointer text-gray-700 hover:text-gray-900', currentPage === 1 && 'hidden')} onClick={prevPage}>Prev</h3>
                        {pageNumbers.map((item, index) => (
                            <div key={index} >
                                <h3 className={classNames('cursor-pointer hover:bg-gray-200 px-3 py-1 mx-1', currentPage === item ? 'hover:bg-black  bg-black text-white' : "")} onClick={() => setCurrentPage(item)}>{item}</h3>
                            </div>
                        ))}
                        <h3 className={calssNames('cursor-pointer text-gray-700 hover:text-gray-900', currentPage === nPages && 'hidden')} onClick={nextPage}>Next</h3>
                    </div>
                </div > :
                <div className="flex flex-col items-center justify-center mx-auto">
                    <img src={NotFound} className="w-full h-[50vh]" alt="img" />
                    <h1 className="md:text-3xl font-semibold mt-3  text-gray-700">Product Not Found</h1>
                </div>
            }
        </div >
    )
}