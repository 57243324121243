import React, { useState, useEffect } from "react"
import { useAuthUser } from "../context/isAuthUser";
import Header from "../component/Header";
import { useProduct } from "../context/productContext";
import { ArrowLeftIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import PageLoader from "../helper/LoadingPageConainter";
import classNames from "../helper/ClassNames";
import { Link } from "react-router-dom";
import NotFound from "../asset/images/product.png"


const UserOrder = () => {
    const { user } = useAuthUser()
    const { orderItem, product } = useProduct()
    const [processOrder, setproccessOrder] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false
    })
    useEffect(() => {
        setTimeout(() => {
            setproccessOrder((prev) => ({
                ...prev,
                step1: true,
            }))
        }, 1000);

    }, [])


    const orderProducts = (items) => {
        const filteredCart = product.data.filter((item1, i) => {
            return items.find((item) => item.productId === item1._id)
        })
        return filteredCart
    }
    const quantity = (items, id) => {
        let qun = 0;
        items.forEach((item) => {
            if (item.productId === id) {
                qun = item.quantity
            }
            return ''
        })
        return qun
    }

    const expectDeliveryDate = (date) => {
        const originalTimestamp = new Date(date);

        const nextTwoDaysTimestamp = new Date(originalTimestamp);
        nextTwoDaysTimestamp.setDate(originalTimestamp.getDate() + 4);

        const nextTwoDaysTimestampString = nextTwoDaysTimestamp.toDateString();

        return nextTwoDaysTimestampString
    }

    if (!user) return null;

    return (
        <Header>
            {orderItem.loading && <PageLoader />}
            {!orderItem.loading &&
                <div className="max-w-6xl mt-5 mx-auto">
                    <Link to='/' className="border focus:ring sm:mx-1 mx-4 focus:ring-gray-300  focus:ring-offset-4 px-5 py-2 rounded-md flex w-fit items-center gap-2 text-sm hover:bg-gray-100 shadow"><ArrowLeftIcon className="w-4 h-4" /> Back to Home</Link>

                    {(orderItem && orderItem.data.length) ? orderItem.data?.map((items, i) => (
                        <div key={i}>
                            <div className="border shadow rounded-md mx-4 sm:mx-1 my-10 grid grid-cols-1 md:grid-cols-2 items-start">
                                <div className="border-r">
                                    {orderProducts(items.items).map((item, i) => (
                                        <div key={i} className="lg:flex items-center gap-10 py-5 px-5">
                                            <img className="aspect-[7/5] lg:w-[20%] lg:rounded-full lg:h-[100px]" src={item.image} alt="logo" />
                                            <div className="w-full">
                                                <p className="md:w-full text-sm pt-3">{item.brandName}</p>
                                                <p className="py-1 text-gray-800 text-sm">Quantity: {quantity(items.items, item._id)}</p>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="border-t mt-4 pt-3 px-5 py-5 flex md:flex-nowrap flex-wrap py-5 justify-between">
                                        <div>
                                            <h3 className="text-md font-medium">Delivery Address:</h3>
                                            {user.address &&
                                                <address className="text-sm text-gray-700 pt-3">
                                                    <p>Post: {user.address?.post || '-'} (po)</p>
                                                    <p>District: {user.address?.district}(dt)</p>
                                                    <p>State: {user.address?.state}</p>
                                                    <p>Phone No: {user.address?.phoneNumber}</p>
                                                    <p>PinCode: {user.address?.pinCode}</p>
                                                </address>
                                            }
                                        </div>
                                        <div>
                                            <h3 className="text-md pt-3 sm:pt-0 font-medium">Help</h3>
                                            <address className="text-sm text-gray-700 pt-3">
                                                <p>Phone no:972993447202</p>
                                                <a className="text-blue-600 border-b border-blue-600" href="mailto:thirupathifuture1999@gamil.com">mail to: {'thirupathifuture1999@gamil.com'}</a>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                                <div className=" my-5 px-5">
                                    <h3 className="text-md font-medium pb-1">Order Details:</h3>
                                    <p className="py-1 text-gray-700 text-sm">Total amount paid : <b>${items.total_amount}</b></p>
                                    <p className="py-1 text-gray-700  text-sm">Order id : <b>{items._id}</b></p>
                                    <div className="py-1 text-gray-700 text-sm">Order placed: <b> <time dateTime={items.order_date}>{`${new Date(items.order_date).toDateString()}`} {`${new Date(items.order_date).toLocaleTimeString('en-US', { timeStyle: 'short' })}`}</time></b></div>
                                    <p className="py-1 text-gray-700 flex gap-4 items-center text-sm">Payment status : <b className="bg-green-200 px-3 py-[8px] rounded-full text-green-600 text-xs flex items-center w-fit gap-1"> <CheckCircleIcon className="w-4 h-4 text-green-600" />Success</b></p>
                                    <div className="border-t mt-4 pt-3  py-5 justify-between">
                                        <h3 className="text-md font-medium">Preparing to ship on {expectDeliveryDate(items.order_date)}
                                        </h3>
                                        <div className="bg-gray-100 shadow mt-3 rounded-lg border h-3 sm:h-4">
                                            <div className={classNames("relative w-fit bg-gray-700 rounded-lg h-3 sm:h-4",
                                                processOrder.step1 && 'bg-gray-800 transition duration-700 ease-in-out  !w-[35%]'
                                            )}>
                                                <div className="absolute -right-2 h-6 w-6 rounded-full bg-gray-800 -top-1"></div>
                                            </div>
                                        </div>
                                        <div className="text-xs gap-2 sm:text-sm flex justify-between pt-3">
                                            <p>Order placed</p>
                                            <p>Order proccessing</p>
                                            <p>Order out of delivery</p>
                                            <p>Delivered</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <div className="flex items-center flex-col mt-4 justify-center text-center">
                        <img className="w-full md:w-[40%]  h-auto" src={NotFound} alt="img" />
                        <h3 className="text-lg text-gray-700 font-medium mt-10">Not found orders</h3>
                        {/* <Link to="/" className="flex items-center gap-3 mt-5 border hover:bg-gray-100 shadow  text-gray-800 rounded-md px-4 py-2 text-sm">
                            <ArrowLeftIcon className="w-5 h-5" />
                            Back to home
                        </Link> */}
                    </div>}
                    {/* {product.data.map((item) => (
                    <div className="flex gap-5 px-10 py-5 border">
                        <img className="w-60 h-40" src={item.image} alt="logo" />
                        <div>
                            <h1 className="text-md text-gray-700 font-medium">{item.brandName}</h1>
                            <p className="text-sm py-1">price: ${item.price}</p>
                            <p className="text-sm py-1">Quantity: {item.quantity}</p>
                        </div>
                    </div>
                ))} */}
                </div>
            }
        </Header>
    )

}

export default UserOrder;