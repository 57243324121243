import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../helper/BaseUrl";

const adminContext = createContext()
const { Provider } = adminContext


export const useAdmin = () => {
    return useContext(adminContext)
}


export default function AdmainProvider({children}){
 
    const [admin,setAdmin] = useState({
        data:{},
        loading:false
    })
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      adminDashboard()
    },[])

    const adminDashboard = async() => {
        try {
          setAdmin((prev) => ({
            ...prev,
            loading:true,
          }))
            const res = await axios.get(`${apiUrl}/admin`)
            if(res.status){
              setAdmin((prev) => ({
                ...prev,
                loading:false,
                data:res.data
              }))
            }
        } catch (error) {
          setAdmin((prev) => ({
            ...prev,
            loading:false,
          }))
        }
    }

    const deleteUser = async (id) => {
        try {
          setLoading(true)
            const res = await axios.delete(`${apiUrl}/delete-user/${id.id}`)
            if(res.status){
              const filter =  admin?.data?.data?.users.filter((item) => (item._id !== id.id ))
               setAdmin((prev) => ({
                ...prev,
                data:{data:{
                  users:filter
                }}
               }))
               setLoading(false)
            }
        } catch (error) {
          setLoading(false)
        }
    }

    const value = {
     admin,
     deleteUser,
     loading
    }
    return <Provider value={value}>{children}</Provider>
}
