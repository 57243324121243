import CatagoriesPage from "../component/CatagoriesPage"
import { useProduct } from "../context/productContext"
import PageLoader from "../helper/LoadingPageConainter"
import { useLocation } from "react-router-dom"
import { useAuthUser } from "../context/isAuthUser"
import Header from "../component/Header"

export default function HeadPhone() {
    const { product, filterData } = useProduct()
    const { user } = useAuthUser()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const filterSearch = searchParams.get('filter');
    const handleData = () => {
        if (filterSearch === "headphone") {
            return filterData.data.filter((item) => item.catagorie === 'headPhone')
        } else {
            return product.data.filter((item) => item.catagorie === 'headPhone');
        }
    }


    const headPhone = handleData()
    if (!user) return null
    if (product.loading) return <PageLoader />
    if (filterData.length === 0) return <PageLoader />

    return (
        <Header>
            <CatagoriesPage data={headPhone} filter="headphone" />
        </Header>
            
    )
}