import { RadioGroup } from "@headlessui/react"
import classNames from "../../helper/ClassNames"

const radioGroup = ({ value, handle, option }) => {
    return (
        <RadioGroup value={value} onChange={handle}>
            <div className="bg-white rounded-md -space-y-px">
                {option.map((setting, settingIdx) => (
                    <RadioGroup.Option
                        key={setting}
                        value={setting}
                        className={({ checked }) =>
                            classNames(
                                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                settingIdx === option.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                checked ? 'border-indigo-200' : 'border-gray-200',
                                'relative  py-3  flex cursor-pointer focus:outline-none'
                            )
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <span
                                    className={classNames(
                                        checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                        active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                        'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                    )}
                                    aria-hidden="true"
                                >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="ml-3 flex flex-col">
                                    <RadioGroup.Label
                                        as="span"
                                        className={classNames(checked ? 'text-blue-800' : 'text-gray-900', 'block text-sm')}
                                    >
                                        {setting} offer
                                    </RadioGroup.Label>

                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
export default radioGroup