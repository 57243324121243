import { EnvelopeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/authContext";
import Logo from "../../asset/images/site.png"
import { toast } from "react-toastify";
import BrandLogo from '../../asset/images/brand.png'
import classNames from "../../helper/ClassNames";

export default function Login() {
    const { loginUser, user, loadBtn } = useAuth()

    const push = useNavigate()
    const [login, setLogin] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        if (user && user.isUser === false) {
            push("/email-verify")
        }

        if (user && user.isUser) {
            push('/')
        }
    }, [user, push])

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setLogin((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        if (login.email !== '' && login.password !== "") {
            await loginUser(login)
        } else {
            toast.error("Input field is requried")
        }
    }



    return (
        <div className="flex bg-gray-50 items-center h-[100vh] w-full justify-evenly">
            <img className="w-[50%] hidden md:block h-fit" src={Logo} alt="logo" />
            <div>
                <img src={BrandLogo} alt='logo' />
                <form onSubmit={handleLogin} className="mx-10">
                    <h2 className='text-gray-600 text-xl font-medium pb-3 text-center uppercase bold'>Login</h2>
                    <p className='text-xs text-center text-gray-600 pb-8'>Don't have account <Link to="/sign-up" className='text-blue-700 text-xs hover:underline'>SignUp</Link></p>

                    <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                        <EnvelopeIcon className='w-5 h-5 text-gray-500' />
                        <input type="email"
                            name='email'
                            required
                            value={login.email}
                            onChange={handleOnChange}
                            placeholder='Enter your email'
                            className="focus:outline-none w-full text-sm"
                        />
                    </div>
                    <div className='flex gap-2 items-center bg-white  shadow rounded py-2 px-3 border-[1px] border-gray-300 my-3'>
                        <EyeSlashIcon className='w-5 h-5 text-gray-500' />
                        <input type="password"
                            name='password'
                            required
                            value={login.password}
                            onChange={handleOnChange}
                            placeholder='Enter password'
                            className="focus:outline-none w-full text-sm"
                        />

                    </div>
                    <Link to="/reset-password" className="text-xs text-gray-600 block text-end hover:underline hover:text-blue-700">Forget Password</Link>
                    <button type="submit" disabled={loadBtn.login} className={classNames('flex items-center text-white my-3 py-3 px-3 w-full  justify-center  text-sm rounded-md', loadBtn.login ? 'bg-gray-500' : 'bg-gray-800')}>{!loadBtn.login ? "Login" : "Loging..."}</button>
                </form>
            </div>
        </div >
    )
}