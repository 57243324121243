import CatagoriesPage from "../component/CatagoriesPage"
import { useProduct } from "../context/productContext"
import PageLoader from "../helper/LoadingPageConainter";
import { useLocation } from "react-router-dom";
import { useAuthUser } from "../context/isAuthUser";
import Header from "../component/Header";

export default function HomeAppliance() {
    const { product, filterData } = useProduct()
    const { user } = useAuthUser()

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const filterSearch = searchParams.get('filter');

    const handleData = () => {
        if (filterSearch === 'homeAppliance') {
            return filterData.data.filter((item) => item.catagorie === 'homeProduct')
        } else {
            return product.data.filter((item) => item.catagorie === 'homeProduct');
        }
    }

    const homeProduct = handleData()

    if (product.loading) return < PageLoader />

    if (!user) return null;

    return (
           <Header>
            <CatagoriesPage data={homeProduct} filter="homeAppliance" />
            </Header>
    )
}