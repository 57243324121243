import { SparklesIcon } from "@heroicons/react/24/outline"
import { catagory } from "../utils/catagory"
import { Link } from "react-router-dom";
import { useAuthUser } from "../context/isAuthUser";
import { useProduct } from "../context/productContext";
import PageLoader from "../helper/LoadingPageConainter";
import classNames from "../helper/ClassNames";
import { StarIcon } from "@heroicons/react/24/solid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../component/Header"

export default function Home() {
    const { user } = useAuthUser()
    const { product } = useProduct()

    const bestProduct = product && product.data.filter((item) => {

        if (item.rating === 4) {
            return item
        }
        return '';
    })
    const lowPrice = product && product.data.filter((item, i) => {
        if (item.price < 10000 && item.rating !== 4 && i < 20) {
            return item
        }
        return '';
    })
    const items = []
    const superProduct = product && product.data.filter((item, i) => {
        if (item.catagorie === 'headPhone' && items.length < 15) {
            items.push(item)
            return items
        }
        return ''
    })

    const mobiles = product && product.data.filter((item, index) => {
        if (item.catagorie === "Mobile" && item.price < 1000) {
            return item
        }
        return '';
    })


    if (product.loading) {
        return <PageLoader />
    }
    if (!user) return null;

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,

    };
    const productSlide = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,


        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false

                }
            },

        ]
    };

    return (
        <Header>
            <div className="pb-20 bg-gray-200">
                <div className="px-3 lg:px-20 xl:md:px-60  pb-20">
                    <Slider {...settings}>
                        <div>
                            <img className="w-full h-40 md:h-auto  md:aspect-[15/5]" src={"https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/fba99b97b0581fb3.jpeg?q=20"} alt="addLogo" />
                        </div>
                        <div>
                            <img className="w-full h-40 md:h-auto md:aspect-[15/5]" src={"https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/15204918e0b8c871.jpg?q=20"} alt="addLogo" />
                        </div>
                        <div>
                            <img className="w-full h-40 md:h-auto md:aspect-[15/5]" src={"https://images-eu.ssl-images-amazon.com/images/G/31/img22/Toys/JanART2024GW/JanArt_DesktopHeroTemplate_3000x1200_Soft_toys_Unrec._CB584087980_.jpg"} alt="addLogo" />
                        </div>
                    </Slider>
                    <div className="">
                        <h3 className="md:text-2xl text-gray-700 py-4 pt-10 font-semibold pb-3">Catagories</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-5 cursor-pointer pb-10">
                            {catagory.map((item, index) => (
                                <Link to={item.navLink} key={index} className="cols-span-1 sm:bg-white md:shadow-lg  md:border md:border-gray-100 rounded-lg transition ease-in-out delay-150 hover:-translate-y-4 hover:scale-90 duration-300">
                                    <img className="mx-auto w-full rounded-md" src={item.image} alt="logo" />
                                    <div className="md:px-3 py-2 sm:py-4">
                                        <span className="text-xs text-yellow-600 flex items-center">All Premimum products. <SparklesIcon className="w-6 h-6 text-yellow-500" /></span>
                                        <h3 className="text-gray-700 py-1 sm:py-0 font-semibold text-xs sm:text-sm">{item.brandName}
                                        </h3>
                                        <span className="text-xs text-gray-500 hidden md:block">Up to <strong className="text-md text-gray-700">{item.offer}</strong>  off all preimum products</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    <section className="bg-white rounded-md pb-10 px-3 md:px-10">
                        <h3 className="md:text-2xl text-gray-700 pt-5 md:pt-10 pb-5 font-semibold">Deal of the Day</h3>
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-5 cursor-pointer">
                            {bestProduct.map((item, index) => (
                                <Link to={`/product/${item._id}`} key={index} className="cols-span-1 shadow-md rounded-sm border border-gray-100">
                                    <img className="w-full rounded-sm" src={item.image} alt="logo" />
                                    <div className="px-3 py-4">
                                        <span className="text-gray-800 font-medium">${item.price}</span>
                                        <span className="text-xs  pl-2 line-through text-gray-400">{item.discountPrice}</span>
                                        {/* <span className="text-xs text-yellow-600  flex items-center">{item.title}</span> */}
                                        <h3 className="text-gray-700 py-1 sm:py-1 font-normal  md:w-40 truncate text-sm">{item.brandName}
                                        </h3>
                                        <span className="text-xs text-white bg-green-700 px-2 py-1">Up to <strong className="text-md text-white">{item.offer}</strong></span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </section>
                    <section className="bg-white my-5 md:my-20 px-10 pb-20 rounded-md">
                        <h2 className="md:text-2xl text-xl text-gray-700 py-10 font-semibold">Super Gadgets</h2>
                        <Slider {...productSlide}>
                            {superProduct.map((item, index) => (
                                <div key={index}>
                                    <Link to={`/product/${item._id}`}>
                                        <img className="w-full md:w-[80%] h-48" src={item.image} alt="logo" />
                                        <div className="px-3 py-4">
                                            <span className="text-gray-800 font-medium">${item.price}</span>
                                            <span className="text-xs  pl-2 line-through text-gray-400">{item.discountPrice}</span>
                                            <span className="text-xs text-yellow-600  flex items-center">{item.title}</span>
                                            <h3 className="text-gray-700 py-1 sm:py-1 font-normal  w-40 truncate text-sm">{item.brandName}
                                            </h3>
                                            {/* <div className="flex items-center mt-2">
                                                {[0, 1, 2, 3, 4].map((rating) => (
                                                    <StarIcon
                                                        key={rating}
                                                        className={classNames(
                                                            item.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                                            'h-5 w-5 flex-shrink-0'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                ))}
                                            </div> */}
                                            {/* <span className="text-xs text-white bg-red-600 px-2 py-1">Up to <strong className="text-md text-white">{item.offer}</strong></span> */}
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </section>
                    <section className="my-10">
                        <h3 className="md:text-2xl text-xl text-gray-700 py-4 font-semibold pb-10">Top mobile accessories for your smartphone</h3>
                        <div className="grid  md:grid-cols-3 gap-10 cursor-pointer">
                            {mobiles.map((item, index) => (
                                <Link to={`/product/${item._id}`} key={index} className="cols-span-1  bg-white px-5">
                                    <h2 className="text-lg text-gray-700 py-4 font-semibold w-[15rem] lg:w-[20rem] truncate">{item.brandName}</h2>
                                    <img className="w-full rounded-sm h-[30vh]" src={item.image} alt="logo" />
                                    <div className="px-3 pt-4 pb-6">
                                        <span className="text-gray-800 font-medium">${item.price}</span>
                                        <span className="text-xs  pl-2 line-through text-gray-400">{item.discountPrice}</span>
                                        <h3 className="text-gray-700 pt-1 sm:pt-1 font-normal pb-2">{item.description}</h3>
                                        <span className="text-xs text-white bg-gray-700 px-2 py-2">Checkout Now <strong className="text-md text-white">{item.offer} offer</strong></span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </section >

                    <section className="mt-16 md:px-4">
                        <h1 className="md:text-2xl text-gray-700 pt-5  font-semibold pb-10">Best Sellers in Computers & Accessories</h1>
                        <div className="grid grid-flow-col gap-5 cursor-pointer">
                            <div className="h-[35vh]  w-[94vw] md:w-[95vw] lg:w-[89vw] xl:w-[70vw]">
                                <Slider {...productSlide}>
                                    {lowPrice.map((item, index) => (
                                        <div key={index}>
                                            <Link to={`/product/${item._id}`}>
                                                <img className="w-full md:w-[80%] h-48" src={item.image} alt="logo" />
                                                <div className="px-3 py-4">
                                                    <span className="text-gray-800 font-medium">${item.price}</span>
                                                    <span className="text-xs  pl-2 line-through text-gray-400">{item.discountPrice}</span>
                                                    <span className="text-xs text-yellow-600  flex items-center">{item.title}</span>
                                                    <h3 className="text-gray-700 py-1 sm:py-1 font-normal  w-40 truncate text-sm">{item.brandName}
                                                    </h3>
                                                    <div className="flex items-center mt-2">
                                                        {[0, 1, 2, 3, 4].map((rating) => (
                                                            <StarIcon
                                                                key={rating}
                                                                className={classNames(
                                                                    item.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                                                    'h-5 w-5 flex-shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        ))}
                                                    </div>
                                                    {/* <span className="text-xs text-white bg-red-600 px-2 py-1">Up to <strong className="text-md text-white">{item.offer}</strong></span> */}
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>

                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </Header>
    )
}