import { ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import AdminLayout from "../../component/admin/AdminLayout";
import { useAdmin } from "../../context/admin";
import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PageLoader from "../../helper/LoadingPageConainter";
import classNames from "../../helper/ClassNames";

const ProductDetails = () => {
    const { admin,  loading } = useAdmin()
    const [search, setSearch] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const [product, setProduct] = useState([])

    useEffect(() => {
        if (product?.length === 0 || loading) {
            setTimeout(() => {
                setProduct(admin.data.data?.product)
            }, (1000));
        }
    }, [admin, product, loading])
    const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage] = useState(7)


    const indexlr = currentPage * recordsPerPage;
    const indexfr = indexlr - recordsPerPage;
    const currentData = product && product.slice(indexfr, indexlr);
    const nPages = product &&  Math.ceil(product.length / recordsPerPage);
    const pageNumbers = nPages !== 0 && [...Array(nPages && nPages + 1).keys()].slice(1);
    function nextPage() {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    }
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
    };
    // eslint-disable-next-line array-callback-return
    const filterUser = currentData?.filter((item) => {
        if (search === '') {
            return item
        } else if (item.price.toString().toLowerCase().includes(search) || item._id.toLowerCase().startsWith(search)) {
            return item
        }
    })

    return (
        <AdminLayout>
            {(admin.loading || product?.length === 0) && <PageLoader />}
            {!admin.loading && product?.length && (
                <>
                    <div className="max-w-7xl py-4 rounded-md mx-auto px-4 sm:px-6 md:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="relative bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        S.NO

                                                    </th>
                                                    {/* <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Product Name
                                                    </th> */}
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Uid
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Price
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Discount
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                       rating
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Image
                                                    </th>
                                                   
                                                    {/* <th
                                                        scope="col"
                                                        className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Image
                                                    </th> */}
                                                    <th scope="col" className="relative px-6 py-3">
                                                        <MagnifyingGlassIcon onClick={() => setShowSearch(!showSearch)} className="w-5 h-5 cursor-pointer" />
                                                    </th>
                                                    {showSearch &&
                                                        <th className="absolute bg-white right-16 block -top-2 z-50 w-[30rem] justify-end flex item-center px-5 gap-4 border shadow-md rounded-full my-3 w-60 text-gray-400 focus-within:text-gray-600">
                                                            <MagnifyingGlassIcon className="w-5 w-5" />
                                                            <input
                                                                className="block py-4 w-full h-full border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                                                placeholder="Enter price or id..."
                                                                type="search"
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                        </th>
                                                    }
                                                </tr>

                                            </thead>
                                            {filterUser?.length ?
                                                <tbody>
                                                    {filterUser.map((item, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                            {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-[4rem] truncate overflow-hidden">{item.brandName}</td> */}
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item._id}</td>
                                                            {/* {edit.open && index === edit.index ? 
                                                             <input
                                                             className="block py-4 w-full h-full border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                                             placeholder="Enter price or id..."
                                                             type="search"
                                                             onChange={(e) => setSearch(e.target.value)}
                                                         />:     */}
                                                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${item.price}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 lowercase">{item.discountPrice}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.rating}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><img className="w-10 h-10 rounded-full" src={item.image} alt="img"></img></td>
                                                          
                                                           {/* <td onClick={() => setEdit((prev) => ({...prev, index:index, open:true}))}><PencilSquareIcon className="w-6 h-6"/></td> */}
                                                           {/* <td onClick={() => deleteUser({ id: item._id })} className="px-6 cursor-pointer py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <TrashIcon className="w-5 h-5 text-red-600" />
                                                            </td> */}
                                                            <td>-</td>
                                                        </tr>
                                                    ))}
                                                </tbody> : ""
                                            }
                                        </table>
                                        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div>
                                                    <p className="text-sm text-gray-700">
                                                        Showing{' '}
                                                        <span className="font-medium">{currentPage}</span> to{' '}
                                                        <span className="font-medium">
                                                            {pageNumbers[pageNumbers.length - 1]}
                                                        </span>{' '}
                                                        results
                                                    </p>
                                                </div>
                                                <div>
                                                    <nav
                                                        className="relative z-0 py-4 flex  items-center rounded-md shadow-sm"
                                                        aria-label="Pagination"
                                                    >
                                                        <button
                                                            onClick={prevPage}
                                                            disabled={currentPage === 1}
                                                            className={classNames(
                                                                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                                                                currentPage === 1 && 'opacity-40'
                                                            )}
                                                        >
                                                            <ChevronLeftIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                            {/* <span className="">Previous</span> */}
                                                        </button>
                                                        {pageNumbers && pageNumbers.map((item, idx) => (
                                                            <div
                                                                key={idx}
                                                                onClick={() => setCurrentPage(item)}
                                                                className={classNames(
                                                                    'z-10 flex relative cursor-pointer inline-flex items-center px-4 py-2 border text-sm font-medium',
                                                                    currentPage === item
                                                                        ? 'bg-gray-900 text-white border-royal-blue-500'
                                                                        : ''
                                                                )}
                                                            >
                                                                {item}
                                                            </div>
                                                        ))}

                                                        <button
                                                            disabled={
                                                                pageNumbers[pageNumbers.length - 1] ===
                                                                currentPage
                                                            }
                                                            onClick={nextPage}
                                                            className={classNames(
                                                                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                                                                pageNumbers[pageNumbers.length - 1] ===
                                                                currentPage && 'opacity-40'
                                                            )}
                                                        >
                                                            {/* <span className="">Next</span> */}
                                                            <ChevronRightIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </AdminLayout>
    )
}
export default ProductDetails;