import Home from "../Home"
import { useEffect } from "react"
import { useAuth } from "../../context/authContext"
import { useLocation, useNavigate } from "react-router-dom"

export default function Confirmation() {
    const { confirmMail, user } = useAuth()
    const push = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        if (token && user && user.isUser === false) {
            confirmMail(token)
        }
    }, [token, user, confirmMail])

    useEffect(() => {
        if (user && user.isUser) {
            push("/")
        }
    }, [user, push])

    return (
        <div className="flex justify-center overflow-hidden">
            <Home />
            <div className="fixed top-0 inset-0  backdrop-blur-lg  z-40"></div>
            <div className="mx-4 flex items-center justify-center absolute top-0 left-40rem mt-8 sm:mx-auto sm:w-full sm:max-w-md z-50">
                <div>
                    <div className="mt-24 text-center">
                        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                            Verify your e-mail address
                        </h2>
                    </div>
                    <div className="px-4 pt-8 mt-8 bg-white rounded-lg shadow-lg sm:px-10 text-gray-500">
                        Please take 3sec time to verify your email address...
                        <hr className="mt-3" />
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
} 