import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { PhotoProvider, PhotoView } from "react-photo-view"
import BtnLoading from "../../helper/BtnLoading";
import { useAuthUser } from "../../context/isAuthUser";
import { toast } from "react-toastify";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Header from "../../component/Header";


export default function UserProfile() {
    const { uploadImage, loadBtn, updateUser } = useAuth()
    const { user } = useAuthUser()
    const [userDetail, setUserDetail] = useState({
        name: "",
        photo: ""
    })
    const [address, setAddress] = useState({
        state: "",
        country: "India",
        pinCode: '',
        district: '',
        phoneNumber: '',
        post: ''
    })

    useEffect(() => {
        if (user) {
            setUserDetail((prev) => ({
                ...prev,
                name: user.name
            }))
            setAddress((prev) => ({
                ...prev,
                ...user?.address,
            }))
        }
    }, [user])

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setUserDetail((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handlImage = (e) => {
        setUserDetail((prev) => ({
            ...prev,
            photo: e.target.files[0]
        }))
    }

    const saveUser = async () => {
        if (userDetail.photo !== '') {
            const file = userDetail.photo
            const formData = new FormData();
            formData.append('photo', file);
            await uploadImage(formData, user?._id)
            setUserDetail({ photo: '' })
        } else {
            if (address.state !== '' && address.post !== '' && address.district !== '' && address.pinCode !== 0) {
                await updateUser(address, user?._id)
                toast.success('Update user successfully')
            } else {
                toast.error("Address field is required")
            }
        }
    }

    const handleAddress = (e) => {
        const { name, value } = e.target
        setAddress((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    if (!user) return null;

    return (
        <Header>
            {user &&
                <div className="max-w-4xl mx-auto shadow-md p-4 rounded-lg   my-3 border bg-white">
                    <Link to="/">
                        <div className="flex items-center gap-1 cursor-pointer text-gray-600 hover:text-blue-700">
                            <ChevronLeftIcon className="w-3 h-3" />
                            <p className="text-xs">Back to home</p>
                        </div>
                    </Link>
                    <div className="flex items-center justify-between border-b pb-4 border-gray-200">
                        <div>
                            <h1 className="text-lg font-bold text-gray-700">Personal Details</h1>
                            <p className="text-xs text-gray-500">User personal Infromation and details</p>
                        </div>
                        {loadBtn.profile ? <div className="w-fit"> <BtnLoading title="Updating..." />  </div> :
                            <button onClick={saveUser} className='w-fit text-white my-3 py-2 px-3 bg-gray-800  text-sm rounded-md'>Update</button>
                        }
                    </div>
                    <div className="flex justify-between flex-wrap">
                        <div className="py-4">
                            <div>
                                <p className="text-md text-gray-900 font-md">Full Name</p>
                                <div className=' bg-white  w-full md:w-fit shadow rounded py-1 mt-2 px-3 border border-gray-300 mb-5'>
                                    <input type="text"
                                        name='name'
                                        required
                                        value={userDetail.name}
                                        onChange={handleOnChange}
                                        placeholder='Enter your name'
                                        className="focus:outline-none w-full md:w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-md text-gray-900 font-md">Email</p>
                                <p className="text-sm text-gray-600">{user?.email}</p>
                            </div>
                            <div className="my-4">
                                <p className="text-md text-gray-900 font-md">Upload Photo</p>

                                <input
                                    name="photo"
                                    accept="image/*"
                                    onChange={handlImage}
                                    className="my-3 text-gray-600 text-sm"
                                    type="file" />

                            </div>

                        </div>
                        <div className="md:mx-20 p-5">
                            {user.photoUrl &&
                                <PhotoProvider>
                                    <PhotoView src={user.photoUrl}>
                                        <img src={user.photoUrl} className="w-48 object-cover border h-48 rounded-full shadow" alt="logo" />
                                    </PhotoView>
                                    <p className="text-xs text-gray-600 text-center py-3">Click img zoom in</p>
                                </PhotoProvider>
                            }
                        </div>
                    </div>
                    <div>
                        <p className="text-md text-gray-900 font-semibold pb-3">Shipping Address</p>
                        <div className="grid md:grid-cols-2">
                            <div>
                                <p className="text-sm text-gray-900 font-medium">Post</p>
                                <div className=' bg-white  w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="text"
                                        name='post'
                                        required
                                        value={address.post}
                                        onChange={handleAddress}
                                        placeholder='Enter your post'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-900 font-md">District</p>
                                <div className=' bg-white w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="text"
                                        name='district'
                                        required
                                        value={address.district}
                                        onChange={handleAddress}
                                        placeholder='Enter your district'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-900 font-md">Pin Code</p>
                                <div className=' bg-white  w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="number"
                                        name='pinCode'
                                        required
                                        value={address.pinCode}
                                        onChange={handleAddress}
                                        placeholder='Enter your pincode'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-900 font-md">state</p>
                                <div className=' bg-white  w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="text"
                                        name='state'
                                        required
                                        value={address.state}
                                        onChange={handleAddress}
                                        placeholder='Enter your state'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-900 font-md">Country</p>
                                <div className=' bg-white  w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="text"
                                        required
                                        value="India"
                                        onChange={handleAddress}
                                        placeholder='Enter your Country'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-900 font-md">Phone Number</p>
                                <div className=' bg-white  w-fit shadow rounded py-1  px-3 border border-gray-300 mb-5'>
                                    <input type="number"
                                        required
                                        name="phoneNumber"
                                        value={address.phoneNumber}
                                        onChange={handleAddress}
                                        placeholder='Enter Mobile Number'
                                        className="focus:outline-none w-fit text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </Header>

    )
}