import CatagoriesPage from "../component/CatagoriesPage"
import { useProduct } from "../context/productContext"
import PageLoader from "../helper/LoadingPageConainter"
import { useLocation } from "react-router-dom"
import { useAuthUser } from "../context/isAuthUser"
import Header from "../component/Header"

export default function Dress() {
    const { product, filterData } = useProduct()
    const { user } = useAuthUser()

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const filterSearch = searchParams.get('filter');


    const handleData = () => {
        if (filterSearch === 'dress') {
            return filterData.data.filter((item) => item.catagorie === 'Dress')
        } else {
            return product.data.filter((item) => item.catagorie === 'Dress');
        }
    }

    const dressProduct = handleData()

    // if (product.loading) return <PageLoader />

    if (!user) return null;

    return (
        <div>
            <Header>
                <CatagoriesPage data={dressProduct} filter="dress" />
            </Header>
        </div>
    )
}