import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { useAuthUser } from '../../context/isAuthUser'
import { useNavigate } from 'react-router-dom'
import { usePayment } from '../../context/paymentContext'
export default function PaymentSuccess() {
  const [open, setOpen] = useState(true)
  const { setPaymentEvent } = usePayment()
  const { user } = useAuthUser()
  const push = useNavigate()


  const handleSuccess = async () => {
    setPaymentEvent((prev) => ({
      ...prev,
      success: false,
      faild: false,
    }))
    push("/")
    setOpen(false)
  }


  if (!user) return null;

  return (
    <div>
      {/* <UserCart/> */}
      <Transition.Root show={open} as={Fragment}>
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed h-full bg-gray-700 opacity-10 inset-0 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block border align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckBadgeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Payment successful
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 block sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    onClick={handleSuccess}
                  >
                    View Order
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition.Root>
    </div>

  )
}