import { UserCircleIcon } from "@heroicons/react/24/outline"
import { StarIcon } from "@heroicons/react/24/solid"
import classNames from './ClassNames'


export default function Review({ data }) {
    return (
        <div>
            <div className="my-2">
                {data.map((review, reviewIdx) => (
                    <div key={reviewIdx} className="flex text-sm text-gray-500 space-x-2">
                        <div className="flex-none py-1">
                            {review.photoUrl ?
                                <img src={review.photoUrl} alt="logo" className="w-10 h-10 bg-gray-100 rounded-full" />
                                : <UserCircleIcon className="w-10 h-10" />
                            }
                        </div>
                        <div className='flex-1 pb-5'>
                            <h3 className="font-medium text-gray-900">{review.userName}</h3>
                            <time dateTime={review.createdAt}>{`${new Date(review.createdAt).toDateString()}`}</time>
                            <p>
                                <time dateTime={review.createdAt}>{`${new Date(review.createdAt).toLocaleTimeString('en-US', { timeStyle: 'short' })}`}</time>
                            </p>

                            <div className="flex items-center mt-2">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                    <StarIcon
                                        key={rating}
                                        className={classNames(
                                            review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                            'h-5 w-5 flex-shrink-0'
                                        )}
                                        aria-hidden="true"
                                    />
                                ))}
                            </div>
                            <div
                                className="mt-1 prose prose-sm max-w-none text-gray-500"
                                dangerouslySetInnerHTML={{ __html: review.review }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}