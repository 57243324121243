import { useContext, createContext, useState } from "react";
import axios from "axios";
import { apiUrl } from "../helper/BaseUrl";
import { useAuth } from "./authContext";
import { useProduct } from "./productContext";

const paymentContext = createContext()
const { Provider } = paymentContext
export const usePayment = () => {
    return useContext(paymentContext)
}

export default function PaymentProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const { setCartItems } = useProduct();
    const { user } = useAuth()
    const [paymentEvent, setPaymentEvent] = useState({
        success: false,
        faild: false,
    })

    // const checkoutPayment = async (payload) => {
    //     try {
    //         setLoading(true)
    //         const res = await axios.post(`${apiUrl}/checkout-session`, payload)
    //         if (res.data.message === 'ok') {
    //             window.open(res.data.url.url, "_blank");
    //             setLoading(false)
    //         }

    //     } catch (error) {
    //         setLoading(false)
    //     }
    // }

    const razoryPayment = async (payload) => {
        try {
            setLoading(true)
            const res = await axios.post(`${apiUrl}/checkout-razorpay`, payload)
            if (res.data.status) {
                const resData = res.data.data
                const options = {
                    key: process.env.REACT_APP_RZY_PAY_SECRATE,
                    amount: resData.amount,
                    currency: 'INR',
                    order_id: resData.id,
                    name: 'ShopySpy',
                    image: '',
                    description: 'Purchase Description',
                    handler: async function (response) {
                        if (response.razorpay_payment_id) {
                            try {
                                const data = { ...response, userId: user._id, amount: resData.amount }
                                const res = await axios.post(`${apiUrl}/verify-payment`, data)
                                if (res.data.status) {
                                    setCartItems(res.data.data)
                                    setPaymentEvent((prev) => ({
                                        ...prev,
                                        success: true
                                    }))
                                }
                                else {
                                    setPaymentEvent((prev) => ({
                                        ...prev,
                                        faild: true
                                    }))
                                    console.log('Payment Failure!');
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }
                        else {
                            console.log('Payment Failure!');
                        }
                    },
                    prefill: {
                        name: user.name,
                        email: user.email,
                        contact: '9876543210',
                    },
                    theme: {
                        color: '#000',
                    },
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const value = {
        // checkoutPayment,
        loading,
        razoryPayment,
        paymentEvent,
        setPaymentEvent
    }

    return <Provider value={value}>{children}</Provider>

}
