import { UserIcon, EnvelopeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import Logo from "../../asset/images/site.png"
// import Spinner from "../../helper/BtnSpinner";
import BrandLogo from '../../asset/images/brand.png'
import classNames from '../../helper/ClassNames'
export default function Registration() {
    const { user, registration, loadBtn } = useAuth();
    const push = useNavigate()
    const [sign, setSign] = useState({
        name: "",
        email: "",
        password: ''
    })
    useEffect(() => {
        if (user) {
            push("/")
        }
    }, [user, push])

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setSign((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const res = await registration(sign)
        if (res.data.message === 'ok') {
            console.log('usfa.....')
            push("/email-verify")
        }
        setSign({ name: "", email: "", password: "" })
    }

    return (
        <div className="flex items-center bg-gray-50 justify-center md:justify-evenly">
            <img className="w-[50%] md:block hidden" src={Logo} alt="logo" />
            <div className="flex justify-between items-center">
                <div className='flex flex-col  items-center justify-center h-[100vh]'>
                    <img src={BrandLogo} alt="logo" />
                    <form onSubmit={onSubmit} className='rounded-lg w-[80%] m-3'>
                        <h2 className='text-gray-600 text-xl font-bold pb-4 text-center'>SIGNUP</h2>
                        <p className='text-xs text-center text-gray-600 pb-6'>Already have account <Link to="/login" className='text-blue-700 text-xs'>Login</Link></p>
                        <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                            <UserIcon className='w-5 h-5 text-gray-500' />
                            <input type="text"
                                name='name'
                                required
                                value={sign.name}
                                onChange={handleOnChange}
                                placeholder='Enter your name'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <div className='flex gap-2 items-center  bg-white  shadow rounded py-2 px-3 border border-gray-300 mb-5'>
                            <EnvelopeIcon className='w-5 h-5 text-gray-500' />
                            <input type="email"
                                name='email'
                                required
                                value={sign.email}
                                onChange={handleOnChange}
                                placeholder='Enter your email'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <div className='flex gap-2 items-center bg-white  shadow rounded py-2 px-3 border-[1px] border-gray-300 my-3'>
                            <EyeSlashIcon className='w-5 h-5 text-gray-500' />
                            <input type="password"
                                name='password'
                                required
                                value={sign.password}
                                onChange={handleOnChange}
                                placeholder='Enter password'
                                className="focus:outline-none w-full text-sm"
                            />
                        </div>
                        <button disabled={loadBtn.signup} type="submit" className={classNames('flex  items-center my-12 text-white  py-3 px-3 w-full  justify-center  text-sm rounded-md', loadBtn.signup ? "bg-gray-400" : "bg-gray-800")}>
                            {loadBtn.signup ? "Loading..." : "SignUp"}
                        </button>
                    </form>
                </div>

            </div >
        </div >
    )
}